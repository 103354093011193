import React, {useEffect} from "react";
import {Modal} from "@root/Components/Controls";
import styles from "./InvitedUsersModal.module.less";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Actions} from "@root/Redux/Tariff/TariffRedux";
import Content from "./Components/Content/Content";

type InvitedUsersModalProps = {
    open: boolean,
    onCancel: () => void
}

const InvitedUserModal = (props: InvitedUsersModalProps) => {
    const dispatch = useAppDispatch();
    const inviteUserFetching = useAppSelector((state) => state.Tariff.inviteUser.fetching);

    const onCancel = () => {
        if (!inviteUserFetching) props.onCancel();
    };
    
    useEffect(() => {
        dispatch(Actions.myInvites({}));
    }, [props.open]);

    return (
        <Modal antProps={{
            open: props.open,
            onCancel,
            footer: null,
            title: "Настройки доступа",
            width: 440,
            className: styles.modal,
        }}>
            <p>
                Отправленные приглашения участникам
            </p>
            <div className={styles.content}>
                <Content />
            </div>
        </Modal>
    );
};

export default InvitedUserModal;
