import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {iActions} from "@root/Redux/Tariff/types";
import {PayloadAction} from "@reduxjs/toolkit";
import {delay} from "redux-saga/effects";
import useNotification from "@root/Hooks/useNotification/useNotification";

const InviteUser = function* (action: PayloadAction<iActions.inviteUser>) {
    const notification = useNotification();
    const {onError, onSuccess, email} = action.payload;
    try {
        yield delay(1000); // УДАЛИТЬ 
        // yield* call(Api.Tariff.inviteUser, {email});
        onSuccess && onSuccess();
        notification({
            type: "info",
            message: "Приглашение отправлено пользователю"
        });
        yield* put(Actions.Tariff._inviteUserSuccess());
    } catch (error) {
        notification({
            type: "error",
            message: "Произошла ошибка при отправке приглашения"
        });
        onError && onError();
        yield* put(Actions.Tariff._inviteUserError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Tariff.inviteUser, InviteUser);
}
