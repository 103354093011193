import React, {useState} from "react";
import {Button} from "@root/Components/Controls";
import InvitedUsersModal from "./Components/InvitedUsersModal/InvitedUsersModal";
import {TeamOutlined} from "@ant-design/icons";
import styles from "./InvitedUsers.module.less";
import Tooltip from "@root/Components/Tooltip/Tooltip";

const InvitedUsers = () => {
    const [openModal, setOpenModal] = useState(false);
    
    return (
        <>
            <Tooltip overlay="Настройки доступа">
                <Button 
                    type="link"
                    onClick={() => setOpenModal(true)}
                    icon={<TeamOutlined className={styles.button}/>} />
            </Tooltip>
            <InvitedUsersModal 
                onCancel={() => setOpenModal(false)} 
                open={openModal} />
        </>
    );
};

export default InvitedUsers;
