import React from "react";
import styles from "./CookiePolitics.module.less";

const CookiePolitics = () => {
    return (
        <div className={styles.cookiePolitics}>
            <h1>
                ПОЛИТИКА ИСПОЛЬЗОВАНИЯ COOKIES сервиса обезличивания неструктурированных документов «DeepDocs»
            </h1>
            <hr />
            <p>
                Здесь вы можете ознакомиться с информацией о том, как Владелец Сервиса обрабатывает данные, собранные в автоматическом режиме в процессе использования Сервиса. Вы можете отказаться от обработки либо отключить Cookies, однако это может сказаться на корректной работе Сайта и\или Сервиса на вашем устройстве (компьютер, планшет или смартфон).
            </p>
            <h2>
                1. Термины и определения
            </h2>
            <p>
                <span>
                    <b>Сервис</b> – сервис обезличивания неструктурированных документов «DeepDocs», реализованного на программном и аппаратном обеспечении Владельца Сервиса. Термины «Сервис» и «программное обеспечение» в контексте соглашения являются равнозначными. Сервис размещён на Сайте по адресу: <a target="_blank" href="https://deepdocs.sberlegal.ru/" rel="noreferrer">https://deepdocs.sberlegal.ru/</a>.
                </span>
                <br/>
                <span>
                    <b>Владелец Сервиса</b> – ООО «Сбер Лигал» (ОГРН: 1187746905004, ИНН: 9705124940; место нахождения: 117312, г. Москва, ул. Вавилова, д. 19). Владелец Сервиса является и владельцем Сайта <a target="_blank" href="https://deepdocs.sberlegal.ru/" rel="noreferrer">https://deepdocs.sberlegal.ru/</a>.
                </span>
            </p>
            <h2>
                2. Что такое Cookies?
            </h2>
            <p>
                <span>
                    Сookies – это небольшой фрагмент данных, который сайт запрашивает у браузера, используемого на вашем компьютере или мобильном устройстве (сведения о дате и времени сессии, IP-адресе, типе и надстройках браузера, времени запроса, местонахождении (геолокации), об оборудовании и действиях пользователя на Сайте). Cookies отражают ваши предпочтения или действия на сайте. Сookies хранятся локально на вашем устройстве. При желании вы можете в любой момент удалить сохраненные Cookies в настройках браузера, установленного на вашем устройстве.
                </span>
            </p>
            <h2>
                3. Для чего мы используем ваши Cookies?
            </h2>
            <p>
                Сookies используются в целях:
            </p>
            <ul>
                <li>
                    оценки эффективности, качества работы, а также последующей доработки и улучшения работы Сайта и/или Сервиса;
                </li>
                <li>
                    информирования пользователей о реализуемом Сервисе;
                </li>
                <li>
                    проведения аналитики действий пользователей на Сайте, проведения аналитики функционирования Сайта для того, чтобы сделать Сервис, доступ к которому предоставляется, включая содержимое Сайта, более простыми и удобными в использовании.
                </li>
                <li>
                    проведения статистических, аналитических и маркетинговых исследований по использованию пользователями Сайта и/или Сервиса, в том числе, но не исключительно, для обеспечения бесперебойной и качественной работы Сайта и/или Сервиса.
                </li>
            </ul>
            <h2>
                4. Как мы обрабатываем ваши Cookies?
            </h2>
            <p>
                Мы обрабатываем данные исключительно в случае, если применяется одно из следующих правовых оснований для такой обработки:
            </p>
            <ul>
                <li>
                    пользователь принял настоящую Политику для одной или нескольких указанных в разделе 3 целей;
                </li>
                <li>
                    обработка необходима для того, чтобы мы имели возможность исполнить обязательства перед пользователями.
                </li>
            </ul>
            <p>
                Данные пользователей не передаются каким-либо третьим лицам, за исключением случаев, предусмотренных настоящей Политикой, ФЗ «О персональных данных» от 27.07.2006 № 152-ФЗ, а также федеральными законами. Передача данных пользователя третьим лицам производится Владельцем Сервиса при условии принятия такими третьими лицами обязательств по обеспечению конфиденциальности и защите полученной информации.
            </p>
            <p>
                В целях использования пользователем Сервиса, а также в целях, указанных в разделе 3 Политики, Владелец Сервиса может передавать данные следующим лицам:
            </p>
            <p>
                сотрудничающим с Владельцем Сервиса юридическим лицам и привлекаемым на основании гражданско-правовых договоров физическим лицам, включая, но не ограничиваясь:
            </p>
            <ol>
                <li>
                    Публичному акционерному обществу «Сбербанк России» (ПАО «Сбербанк»), с местонахождением по адресу: 117312, г. Москва, ул. Вавилова, д. 19;
                </li>
                <li>
                    Обществу с ограниченной ответственностью «Облачные технологии» (ИНН 7736279160, ОГРН 5167746080057), с местонахождением по адресу: 117312, г. Москва, ул. Вавилова, д. 23, стр. 1, комната n1.207.
                </li>
            </ol>
            <h2>
                5. Какие виды Сookies мы используем?
            </h2>
            <table>
                <tbody>
                    <tr>
                        <td >
                            Виды
                        </td>
                        <td >
                            Описание
                        </td>
                    </tr>
                    <tr>
                        <td >
                            Сессионные
                        </td>
                        <td >
                            Существуют только во временной памяти в течение времени, когда Пользователь находится на странице Сайта. Браузеры обычно удаляют сессионные Cookies после того, как вы закрываете окно Сайта. Сессионные Cookies позволяют сайту помнить информацию о вашем выборе при предыдущем посещении Сайта, чтобы избежать необходимости повторного ввода информации.
                        </td>
                    </tr>
                    <tr>
                        <td >
                            Постоянные
                        </td>
                        <td >
                            Сookies, которые хранятся на вашем компьютере и не удаляются при закрытии браузера. Постоянные Сookies могут сохранять пользовательские настройки для Сайта, позволяя использовать эти предпочтения при последующем использовании Сервиса. Такие Cookies позволяют идентифицировать вас как уникального Пользователя Сайта и при возвращении на Сайт помогают восстановить информацию о вас и ранее совершенных действиях.
                        </td>
                    </tr>
                    <tr>
                        <td >
                            Статистические
                        </td>
                        <td >
                            <span>
                                Включают в себя информацию о том, как вы используете Сайт. Например, какие страницы вы посещаете, по каким ссылкам переходите.
                            </span>
                            <br />
                            <span>
                                Главная цель таких Cookies - улучшение функций Сайта.
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h2>
                6. Как отказаться от обработки Сookies?
            </h2>
            <p>
                В случае отказа от обработки Cookies вы должны изменить настройки браузера на своем устройстве. В таком случае Сайт будет использовать только те Cookies, которые строго необходимы для функционирования Сайта и Сервиса.
            </p>
            <h2>
                7. Дополнения и изменения в настоящую Политику.
            </h2>
            <p>
                Владелец Сервиса имеет право в любое время вносить изменения и дополнения в настоящую Политику.
            </p>
            <p>
                Дополнения и изменения в Политику размещаются на Сайте, вступают в силу и действительны незамедлительно после их публикации в свободном доступе на Сайте. При каждом входе на Сайт вам следует ознакомиться с актуальными положениями Политики на предмет внесенных в нее изменений и/или дополнений.
            </p>
            <h2>
                8. Заключительные положения.
            </h2>
            <p>
                Пользователи могут получить любые разъяснения, информацию по интересующим их вопросам, обратившись к Владельцу Сервиса с помощью электронной почты на адрес: <a href="mailto:sberpravo@sberbank.ru">sberpravo@sberbank.ru</a> с пометкой «Сервис обезличивания неструктурированных документов «DeepDocs».
            </p>
            <p>
                Настоящая Политика действует бессрочно до даты ее замены новой версией путем размещения в свободном (открытом) доступе на Сайте по адресу: <a target="_blank" href="https://deepdocs.sberlegal.ru/documents/cookie-politics" rel="noreferrer">https://deepdocs.sberlegal.ru/documents/cookie-politics</a>.
            </p>
        </div>

    );
};

export default CookiePolitics;
