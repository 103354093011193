import React from "react";
import styles from "./Registration.module.less";
import {Form} from "antd";
import {Input, Button, FormItem, Checkbox} from "@components/Controls";
import Actions from "@actions";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Auth} from "@root/Api/AuthApi/types";
import Navigation from "../../Modules/Navigation/Navigation";
import routes from "@routes";
import Title from "@root/Components/Title/Title";
import {Link, useHistory} from "react-router-dom";
import {confirmPasswordRules, emailRules, passwordRules, personalDataPolicyRules, privacyPolicyRules} from "@utils/Form/Rules";

const Registration = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const state = useAppSelector((state) => state.Auth.registration);

    const redirect = () => {
        history.push(routes.auth.login);
    };

    const onRegistration = (values: Auth.iRegistration) => {
        dispatch(Actions.Auth.userRegistration({
            ...values,
            redirect
        }));
    };

    return (
        <>
            <Title
                variant="h1" 
                className={styles.title}>
                Регистрация
            </Title>
            {state.error && (
                <div className={styles.errorText}>{state.errMsg}</div>
            )}
            <Form
                form={form}
                name="register"
                onFinish={onRegistration}
                layout="vertical"
                scrollToFirstError
                className={styles.form}>
                <FormItem
                    name="email"
                    className={styles.item}
                    label="E-mail"
                    rules={emailRules}>
                    <Input
                        allowClear
                        className={styles.input}
                        placeholder="Введите ваш email адрес" 
                        disabled={state.fetching}
                    />
                </FormItem>
                <FormItem
                    name="password"
                    className={styles.item}
                    label="Пароль"
                    rules={passwordRules}
                    required
                    dependencies={["confirm"]}>
                    <Input.Password 
                        disabled={state.fetching}
                        className={styles.input}
                        placeholder="Введите пароль"
                    />
                </FormItem>
                <FormItem
                    name="confirm"
                    className={styles.item}
                    label="Подтверждение пароля"
                    dependencies={["password"]}
                    required
                    rules={confirmPasswordRules}>
                    <Input.Password 
                        disabled={state.fetching}
                        className={styles.input}
                        placeholder="Повторите пароль"
                    />
                </FormItem>
                <FormItem 
                    rules={personalDataPolicyRules}
                    name="personal_data_policy"
                    className={styles.checkbox}
                    valuePropName="checked">
                    <Checkbox disabled={state.fetching}>
                        Даю согласие на обработку своих персональных данных в соответствии с <Link
                            to={routes.ld.personal_data_policy}
                            target="_blank"
                            rel="noopener noreferrer">
                                Политикой обработки персональных данных
                        </Link>
                    </Checkbox>
                </FormItem>
                <FormItem 
                    rules={privacyPolicyRules}
                    name="privacy_policy"
                    className={styles.checkbox}
                    valuePropName="checked">
                    <Checkbox disabled={state.fetching}>
                        Подтверждаю ознакомление с <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to={routes.ld.privacy_policy}>
                            Политикой конфиденциальности
                        </Link>
                    </Checkbox>
                </FormItem>
                <div className={styles.controls}>
                    <FormItem>
                        <Button
                            loading={state.fetching}
                            size="large"
                            type="primary"
                            className={styles.button}
                            htmlType="submit">
                            Зарегистрироваться
                        </Button>
                    </FormItem>
                    <div className={styles.link}>
                        <Button
                            type="link">
                            <Navigation route={routes.auth.login}>
                                Уже зарегистрированы?
                            </Navigation>
                        </Button>
                    </div>
                </div>
            </Form>
        </>

    );
};

export default Registration;
