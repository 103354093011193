import {RuleObject} from "antd/lib/form";

export default [
    () => ({
        validator(_rule: RuleObject, value: any) {
            if (value === true) {
                return Promise.resolve();
            }
            return Promise.reject(new Error("Требуется согласие на обработку персональных данных"));
        },
    }),
];
