import React from "react";
import styles from "./Recovery.module.less";
import Actions from "@actions";
import {Button, FormItem, Input} from "@root/Components/Controls";
import {Form} from "antd";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Auth} from "@root/Api/AuthApi/types";
import Title from "@root/Components/Title/Title";
import Navigation from "../../Modules/Navigation/Navigation";
import routes from "@routes";
import {emailRules} from "@utils/Form/Rules";

const Recovery = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.Auth.recovery);

    const onRecovery = (values: Auth.iRecovery) => {
        dispatch(Actions.Auth.userRecovery(values));
    };

    return (
        <div className={styles.wrapper}>
            <Title 
                variant="h1"
                className={styles.title}>
                Восстановление
            </Title>
            <Form
                name="Recovery"
                layout="vertical"
                onFinish={onRecovery}>
                <FormItem
                    name="email"
                    label="E-mail"
                    rules={emailRules}>
                    <Input
                        allowClear
                        className={styles.input}
                        placeholder="Введите email"
                    />
                </FormItem>
                <div className={styles.controls}>
                    <Form.Item>
                        <Button
                            size="large"
                            type="primary"
                            loading={state.fetching}
                            htmlType="submit"
                            className={styles.button}>
                            Восстановить пароль
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="link">
                            <Navigation route={routes.auth.login}>
                                Авторизоваться
                            </Navigation>
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="link">
                            <Navigation route={routes.auth.registration}>
                                Зарегистрироваться
                            </Navigation>
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default Recovery;
