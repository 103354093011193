import Request from "../Request";
import {Auth} from "./types";
import Cookies from "universal-cookie";
import Endpoints from "./endpoints";
import {decode, jwtToken} from "@utils/JWT/decode";
import routes from "@root/Routes/Routes";

const cookies = new Cookies();

export default {
    registration: (params: Auth.iRegistration) => Request.post<Auth.oRegistration>(`${Endpoints.authorization.registration}`, {
        email: params.email,
        password: params.password,
        personal_data_policy: params.personal_data_policy,
        privacy_policy: params.privacy_policy
    }),
    login: (params: Auth.iLogin) => Request.post<Auth.oLogin>(`${Endpoints.authorization.auth}`, params),
    refresh: () => {
        return Request.post<Auth.oRefresh>(`${Endpoints.authorization.refresh}`)
            .then(({data}) => {
                const decodedAccess:jwtToken = decode(data.access_token);
                cookies.set(
                    "access_token",
                    data.access_token,
                    {
                        // TODO Пока нет SSL сертификата, убираю это
                        // secure: true,
                        expires: new Date(decodedAccess.exp * 1000),
                        path: "/"
                    });
            })
            .catch(() => {
                // обнуляем сторы и предалагаем пользователю авторизоваться если на обновление токена получаем ошибку
                window.location.href = routes.auth.login;
            });
    },
    recovery: (params: Auth.iRecovery) => {
        return Request.post<void>(`${Endpoints.authorization.recovery}`, {...params});
    },
    reset: (params: Auth.iReset) => {
        return Request.patch<void>(`${Endpoints.authorization.reset}/${params.token}`, {
            new_password: params.new_password,
            confirm_password: params.confirm_password
        });
    },
    verify: (params: Auth.iVerify) => {
        return Request.post<void>(`${Endpoints.authorization.verify}/${params.token}`);
    },
    verifyRequest: (params: Auth.iVerifyRequest) => {
        return Request.post<void>(`${Endpoints.authorization.verifyRequest}`, {...params});
    },
    logout: () => Request.post(`${Endpoints.authorization.logout}`),
    getOauthInfo: (params: Auth.iGetOauthInfo) => {
        const url =  Endpoints.authorization.getOauthInfo.replace("{vendor}", params.vendor);
        return Request.get<Auth.oGetOauthInfo>(url);
    },
    oauthAuth: (params: Auth.iOauthAuth) => {
        const url =  Endpoints.authorization.OauthAuth.replace("{vendor}", params.vendor);
        return Request.post<Auth.oOauthAuth>(url, {
            auth_code:params.auth_code,
            state: params.state,
            redirect_uri: params.redirect_uri
        });
    }
};
