import React from "react";
import styles from "./LegalDocument.module.less";
import Logo from "./Modules/Logo/Logo";
import useTitle from "@root/Hooks/useTitle/useTitle";
import ContentController from "./Controller/ContentController";

const LegalDocument = () => {
    useTitle("DeepDocs - Юридические документы");
    
    return (
        <div className={styles.wrapper}>
            <Logo />
            <div className={styles.content}>
                <ContentController />
            </div>
        </div>
    );
};

export default LegalDocument;
