import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import routes from "@routes";
import Pages from "@pages/index";
import ProjectRouter from "./Project/ProjectRouter";
import AuthRouter from "./Auth/AuthRouter";
import CheckAuthorized from "@containers/CheckAuthorized/CheckAuthorized";

const RoutesController = () => {
    return (
        <Switch>
            <Route exact path={routes.auth.sberIDAuth()}>
                <Pages.SberIDAuth vendor="sber_id" />
            </Route>
            <Route exact path={routes.auth.sbiAuth()}>
                <Pages.SberIDAuth vendor="sber_business_id" />
            </Route>
            <Route exact path="/">
                <Pages.Landing />
            </Route>
            <Route
                exact
                path={[
                    routes.ld.cookie_politics,
                    routes.ld.personal_data_policy,
                    routes.ld.privacy_policy,
                ]}>
                <Pages.LegalDocument />
            </Route>
            <Route exact path={routes.lk.settings}>
                <CheckAuthorized>
                    <Pages.Lk.Settings />
                </CheckAuthorized>
            </Route>
            <Route exact path={routes.lk.projects}>
                <CheckAuthorized>
                    <Pages.Lk.Projects />
                </CheckAuthorized>
            </Route>
            {/* <Route exact path={routes.lk.statistics}>
                <CheckAuthorized>
                    <Pages.Lk.Statistics />
                </CheckAuthorized>
            </Route> */}
            <Route exact path={routes.lk.templates}>
                <CheckAuthorized>
                    <Pages.Lk.Templates />
                </CheckAuthorized>
            </Route>
            <Route path={routes.lk.project.root()}>
                <CheckAuthorized>
                    <ProjectRouter />
                </CheckAuthorized>
            </Route>
            <Route path={routes.auth.root}>
                <CheckAuthorized unauthView={<AuthRouter />}>
                    <Redirect to={routes.lk.projects} />
                </CheckAuthorized>
            </Route>
            <Route path="*">
                <Pages.Undefined />
            </Route>
        </Switch>
    );
};

export default RoutesController;
