import React from "react";
import Info from "../../Info/Info";
import Controls from "../../Controls/Controls";

const MyTariff = () => {
    return (
        <div>
            <Controls />
            <Info />
        </div>
    );
};

export default MyTariff;
