import React from "react";
import {Tabs} from "antd";
import Profile from "./Profile/Profile";
import Tariff from "./Tariff/Tariff";
import Team from "./Team/Team";
import styles from "./Content.module.less";
// TODO для обычных пользователей можно вырезать часть страниц или заменить их вьюшку (сделать дубль в доработками) на основе роли
const Content = () => {
    const items = [
        {
            key: "Управление подпиской",
            label: "Управление подпиской",
            children: <Tariff />
        },
        {
            key: "Команда",
            label: "Команда",
            children: <Team />
        },
        {
            key: "Личный кабинет",
            label: "Личный кабинет",
            children: <Profile />
        },
    ];
    // return <Profile />;

    return (
        <div className={styles.wrapper}>
            <Tabs items={items} />
        </div>
    );
};

export default Content;
