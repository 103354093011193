import React from "react";

const Icon = () => {
    return (
        <svg width="66" height="72" viewBox="0 0 66 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M65.5 28.6454C65.5 27.6359 64.6732 26.8281 63.6663 26.8281H2.3337C1.3268 26.8281 0.5 27.6359 0.5 28.6454V65.5524C0.5 68.8434 3.19946 71.5008 6.5163 71.5008H59.4837C62.8005 71.5008 65.5 68.8434 65.5 65.5524V28.6454Z" fill="#E7F6EA" stroke="#2C2E34"/>
            <path d="M7.40039 59.4801V59.8313L7.73071 59.9505L23.2232 65.5396L23.2783 65.5595L23.3365 65.5661L33.0155 66.6655L33.088 66.6738L33.1597 66.661L56.358 62.525L56.5478 62.4911L56.6658 62.3385L58.6391 59.7859L58.7435 59.6509V59.4801V25.7874C58.7435 24.887 58.0077 24.166 57.1096 24.166H9.0343C8.13626 24.166 7.40039 24.887 7.40039 25.7874V59.4801Z" fill="#EEEFEE" stroke="#2C2E34"/>
            <path d="M32.5 25H33.5V66.5493H32.5V25Z" fill="#2C2E34"/>
            <path d="M36.783 34.9443V34.4443H36.283H29.8555H29.3555V34.9443V39.2588C29.3555 40.8418 30.6531 42.1146 32.2407 42.1146H33.8978C35.4855 42.1146 36.783 40.8418 36.783 39.2588V34.9443Z" fill="#B3B3B3" stroke="#2C2E34"/>
            <path d="M47.8146 15.0688C47.8146 7.01679 41.2075 0.5 33.0704 0.5C24.9333 0.5 18.3262 7.01679 18.3262 15.0688C18.3262 18.1873 19.3188 21.0774 21.0074 23.447L27.7749 33.8527V35.0749C27.7749 36.2196 28.7128 37.1371 29.8566 37.1371H36.2842C37.428 37.1371 38.3659 36.2196 38.3659 35.0749V33.8527L45.1334 23.447C46.8219 21.0774 47.8146 18.1874 47.8146 15.0688Z" fill="#E7F6EA" stroke="#2C2E34"/>
            <path d="M34.0066 36.6371H36.1492L36.8947 24.1436H38.1148C39.8608 24.1436 41.2812 22.7407 41.2812 21.0163V20.888C41.2812 19.1636 39.8608 17.7607 38.1148 17.7607C36.441 17.7607 35.0526 19.0536 34.954 20.704L34.8751 22.0278H31.119L31.0401 20.704C30.9417 19.0536 29.5531 17.7607 27.8793 17.7607C26.1333 17.7607 24.7129 19.1636 24.7129 20.888V21.0163C24.7129 22.7407 26.1333 24.1436 27.8793 24.1436H29.0994L29.8447 36.6367C29.848 36.6367 29.8513 36.6371 29.8546 36.6371H31.9875C31.9875 36.6164 31.2453 24.1436 31.2453 24.1436H34.7488C34.749 24.1434 34.0068 36.6164 34.0066 36.6371ZM37.0925 20.8285C37.1243 20.2946 37.5735 19.8764 38.1148 19.8764C38.6797 19.8764 39.1391 20.3301 39.1391 20.8878V21.0162C39.1391 21.5739 38.6797 22.0277 38.1148 22.0277H37.0209L37.0925 20.8285ZM27.8794 22.0277C27.3146 22.0277 26.8552 21.5739 26.8552 21.0162V20.8878C26.8552 20.3301 27.3146 19.8764 27.8794 19.8764C28.4209 19.8764 28.8699 20.2946 28.9018 20.8285L28.9733 22.0277H27.8794Z" fill="#00CA81"/>
            <path d="M51.8313 7.97437C51.5338 7.97437 51.2378 7.85264 51.026 7.61441C50.6357 7.17546 50.6797 6.50688 51.1242 6.12153L53.4612 4.09491C53.9057 3.70956 54.5825 3.75286 54.9727 4.19196C55.3629 4.6309 55.319 5.29948 54.8744 5.68484L52.5375 7.71145C52.3343 7.88776 52.0822 7.97437 51.8313 7.97437Z" fill="#00CA81"/>
            <path d="M54.1674 16.6455C53.9165 16.6455 53.6645 16.559 53.461 16.3826L51.1241 14.3558C50.6797 13.9703 50.6357 13.3019 51.026 12.8629C51.4163 12.424 52.0931 12.3802 52.5375 12.7659L54.8744 14.7926C55.3188 15.1781 55.3628 15.8466 54.9725 16.2855C54.7609 16.5237 54.4648 16.6455 54.1674 16.6455Z" fill="#00CA81"/>
            <path d="M14.306 7.97541C14.0551 7.97541 13.8031 7.88895 13.5998 7.71249L11.2629 5.68588C10.8183 5.30053 10.7744 4.63195 11.1646 4.193C11.5548 3.75405 12.2317 3.71032 12.6761 4.09596L15.013 6.12257C15.4576 6.50792 15.5014 7.1765 15.1113 7.61545C14.8996 7.85369 14.6036 7.97541 14.306 7.97541Z" fill="#00CA81"/>
            <path d="M11.97 16.6444C11.6725 16.6444 11.3765 16.5227 11.1647 16.2845C10.7744 15.8455 10.8184 15.1771 11.2628 14.7916L13.5997 12.7648C14.0443 12.3795 14.7211 12.4228 15.1112 12.8619C15.5015 13.3008 15.4575 13.9693 15.0131 14.3548L12.6762 16.3815C12.4729 16.5578 12.2209 16.6444 11.97 16.6444Z" fill="#00CA81"/>
            <path d="M22.5201 37.6949H11.9695C11.378 37.6949 10.8984 37.2214 10.8984 36.637C10.8984 36.0526 11.378 35.5791 11.9695 35.5791H22.5201C23.1116 35.5791 23.5912 36.0526 23.5912 36.637C23.5912 37.2214 23.1116 37.6949 22.5201 37.6949Z" fill="#B3B3B3"/>
            <path d="M25.7838 43.0757H13.9051C13.3135 43.0757 12.834 42.6022 12.834 42.0178C12.834 41.4335 13.3135 40.96 13.9051 40.96H25.7838C26.3753 40.96 26.8549 41.4335 26.8549 42.0178C26.8549 42.6022 26.3753 43.0757 25.7838 43.0757Z" fill="#B3B3B3"/>
            <path d="M25.7838 48.231H13.9051C13.3135 48.231 12.834 47.7575 12.834 47.1731C12.834 46.5887 13.3135 46.1152 13.9051 46.1152H25.7838C26.3753 46.1152 26.8549 46.5887 26.8549 47.1731C26.8549 47.7575 26.3753 48.231 25.7838 48.231Z" fill="#B3B3B3"/>
            <path d="M25.7838 53.3843H13.9051C13.3135 53.3843 12.834 52.9108 12.834 52.3264C12.834 51.7421 13.3135 51.2686 13.9051 51.2686H25.7838C26.3753 51.2686 26.8549 51.7421 26.8549 52.3264C26.8549 52.9108 26.3753 53.3843 25.7838 53.3843Z" fill="#B3B3B3"/>
            <path d="M54.1685 37.6949H43.6179C43.0264 37.6949 42.5469 37.2214 42.5469 36.637C42.5469 36.0526 43.0264 35.5791 43.6179 35.5791H54.1685C54.7601 35.5791 55.2396 36.0526 55.2396 36.637C55.2396 37.2214 54.7601 37.6949 54.1685 37.6949Z" fill="#B3B3B3"/>
            <path d="M52.235 43.0757H40.3562C39.7647 43.0757 39.2852 42.6022 39.2852 42.0178C39.2852 41.4335 39.7647 40.96 40.3562 40.96H52.235C52.8265 40.96 53.306 41.4335 53.306 42.0178C53.306 42.6022 52.8266 43.0757 52.235 43.0757Z" fill="#B3B3B3"/>
            <path d="M52.235 48.231H40.3562C39.7647 48.231 39.2852 47.7575 39.2852 47.1731C39.2852 46.5887 39.7647 46.1152 40.3562 46.1152H52.235C52.8265 46.1152 53.306 46.5887 53.306 47.1731C53.306 47.7575 52.8266 48.231 52.235 48.231Z" fill="#B3B3B3"/>
            <path d="M52.235 53.3843H40.3562C39.7647 53.3843 39.2852 52.9108 39.2852 52.3264C39.2852 51.7421 39.7647 51.2686 40.3562 51.2686H52.235C52.8265 51.2686 53.306 51.7421 53.306 52.3264C53.306 52.9108 52.8266 53.3843 52.235 53.3843Z" fill="#B3B3B3"/>
            <mask id="path-19-outside-1_2495_6029" maskUnits="userSpaceOnUse" x="6.89648" y="58.4785" width="53" height="9" fill="black">
                <rect fill="white" x="6.89648" y="58.4785" width="53" height="9"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M33.0674 66.0708C33.1195 62.4211 36.1311 59.4785 39.8387 59.4785H58.2382V63.5636V64.5648V64.5673C58.2382 65.4785 57.4914 66.2162 56.5702 66.2162H33.0667V66.2161H9.5645C8.64338 66.2161 7.89648 65.4784 7.89648 64.5672V64.5648V59.481V59.4785H26.296C30.0036 59.4785 33.0152 62.4211 33.0674 66.0708Z"/>
            </mask>
            <path fillRule="evenodd" clipRule="evenodd" d="M33.0674 66.0708C33.1195 62.4211 36.1311 59.4785 39.8387 59.4785H58.2382V63.5636V64.5648V64.5673C58.2382 65.4785 57.4914 66.2162 56.5702 66.2162H33.0667V66.2161H9.5645C8.64338 66.2161 7.89648 65.4784 7.89648 64.5672V64.5648V59.481V59.4785H26.296C30.0036 59.4785 33.0152 62.4211 33.0674 66.0708Z" fill="#B3B3B3"/>
            <path d="M33.0674 66.0708L32.0675 66.0851L34.0673 66.0851L33.0674 66.0708ZM58.2382 59.4785H59.2382V58.4785H58.2382V59.4785ZM33.0667 66.2162H32.0667V67.2162H33.0667V66.2162ZM33.0667 66.2161H34.0667V65.2161H33.0667V66.2161ZM7.89648 59.4785V58.4785H6.89648V59.4785H7.89648ZM34.0673 66.0851C34.1115 62.9927 36.6671 60.4785 39.8387 60.4785V58.4785C35.5951 58.4785 32.1276 61.8495 32.0675 66.0565L34.0673 66.0851ZM39.8387 60.4785H58.2382V58.4785H39.8387V60.4785ZM57.2382 59.4785V63.5636H59.2382V59.4785H57.2382ZM57.2382 63.5636V64.5648H59.2382V63.5636H57.2382ZM57.2382 64.5648V64.5673H59.2382V64.5648H57.2382ZM57.2382 64.5673C57.2382 64.9152 56.9502 65.2162 56.5702 65.2162V67.2162C58.0325 67.2162 59.2382 66.0419 59.2382 64.5673H57.2382ZM56.5702 65.2162H33.0667V67.2162H56.5702V65.2162ZM34.0667 66.2162V66.2161H32.0667V66.2162H34.0667ZM33.0667 65.2161H9.5645V67.2161H33.0667V65.2161ZM9.5645 65.2161C9.1845 65.2161 8.89648 64.915 8.89648 64.5672H6.89648C6.89648 66.0418 8.10225 67.2161 9.5645 67.2161V65.2161ZM8.89648 64.5672V64.5648H6.89648V64.5672H8.89648ZM8.89648 64.5648V59.481H6.89648V64.5648H8.89648ZM8.89648 59.481V59.4785H6.89648V59.481H8.89648ZM7.89648 60.4785H26.296V58.4785H7.89648V60.4785ZM26.296 60.4785C29.4676 60.4785 32.0233 62.9927 32.0675 66.0851L34.0673 66.0565C34.0071 61.8495 30.5397 58.4785 26.296 58.4785V60.4785Z" fill="#2C2E34" mask="url(#path-19-outside-1_2495_6029)"/>
        </svg>
    );
};

export default Icon;
