import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {iState, iActions} from "./types";
import {
    getFullState,
    requestStart, 
    requestError,
    requestSuccess,
    getShortState
} from "@root/Utils/Redux/getRequestState";

const initialState: iState.Value = {
    myTariff: getFullState(),
    unSubscribe: getShortState(),
    addUsers: getShortState(),
    addPages: getShortState(),
    inviteUser: getShortState(),
    cancelInvite: getShortState(),
    myInvites: getFullState(),
    myTariffUsers: getFullState(),
    deleteUser: getShortState(),
    searchUsersString: ""
};

const Slice = createSlice({
    initialState,
    name: "Tariff",
    reducers: {
        myTariff: (state, action: PayloadAction<iActions.myTariff>) => {
            requestStart(state.myTariff);
        },
        _myTariffSuccess: (state, action:PayloadAction<iActions._myTariffSuccess>) => {
            requestSuccess(state.myTariff, action.payload);
        },
        _myTariffError: (state) => {
            requestError(state.myTariff);
        },
        unSubscribe: (state, action: PayloadAction<iActions.myTariff>) => {
            requestStart(state.unSubscribe);
        },
        _unSubscribeSuccess: (state) => {
            if (state.myTariff.data) {
                state.myTariff.data.plan.active = false;
            }
            requestSuccess(state.unSubscribe);
        },
        _unSubscribeError: (state) => {
            requestError(state.unSubscribe);
        },
        addUsers: (state, action: PayloadAction<iActions.addUsers>) => {
            requestStart(state.addUsers);
        },
        _addUsersSuccess: (state, action: PayloadAction<iActions._addUsersSuccess>) => {
            if (state.myTariff.data) {
                state.myTariff.data.users.overall = state.myTariff.data.users.overall + action.payload.addedCount;
                state.myTariff.data.next_pay.price = action.payload.newPrice;
            }
            requestSuccess(state.addUsers);
        },
        _addUsersError: (state) => {
            requestError(state.addUsers);
        },
        addPages: (state, action: PayloadAction<iActions.addPages>) => {
            requestStart(state.addPages);
        },
        _addPagesSuccess: (state, action: PayloadAction<iActions._addPagesSuccess>) => {
            if (state.myTariff.data) {
                state.myTariff.data.pages.overall = state.myTariff.data.pages.overall + action.payload.addedCount;
                state.myTariff.data.next_pay.price = action.payload.newPrice;
            }
            requestSuccess(state.addPages);
        },
        _addPagesError: (state) => {
            requestError(state.addPages);
        },
        inviteUser: (state, action: PayloadAction<iActions.inviteUser>) => {
            requestStart(state.inviteUser);
        },
        _inviteUserSuccess: (state) => {
            requestSuccess(state.inviteUser);
        },
        _inviteUserError: (state) => {
            requestError(state.inviteUser);
        },
        myInvites: (state, action: PayloadAction<iActions.myInvites>) => {
            requestStart(state.myInvites);
        },
        _myInvitesSuccess: (state, action: PayloadAction<iActions._myInvitesSuccess>) => {
            requestSuccess(state.myInvites, action.payload);
        },
        _myInvitesError: (state) => {
            requestError(state.myInvites);
        },
        cancelInvite: (state, action: PayloadAction<iActions.cancelInvite>) => {
            requestStart(state.cancelInvite);
        },
        _cancelInviteSuccess: (state, action: PayloadAction<iActions._cancelInviteSuccess>) => {
            if (state.myInvites.data) {
                state.myInvites.data = state.myInvites.data.filter((invite) => invite !== action.payload.email);
            }
            requestSuccess(state.cancelInvite);
        },
        _cancelInviteError: (state) => {
            requestError(state.cancelInvite);
        },
        myTariffUsers: (state, action: PayloadAction<iActions.myTariffUsers>) => {
            requestStart(state.myTariffUsers);
        },
        _myTariffUsersSuccess: (state, action: PayloadAction<iActions._myTariffUsersSuccess>) => {
            requestSuccess(state.myTariffUsers, action.payload);
        },
        _myTariffUsersError: (state) => {
            requestError(state.myTariffUsers);
        },
        deleteUser: (state, action: PayloadAction<iActions.deleteUser>) => {
            requestStart(state.deleteUser);
        },
        _deleteUserSuccess: (state, action: PayloadAction<iActions._deleteUserSuccess>) => {
            if (state.myTariffUsers.data) {
                state.myTariffUsers.data = state.myTariffUsers.data.filter((user) => user.email !== action.payload.email);
            }
            requestSuccess(state.deleteUser);
        },
        _deleteUserError: (state) => {
            requestError(state.deleteUser);
        },
        updateSearchUsersString: (state, action: PayloadAction<iActions.updateSearchUsersString>) => {
            state.searchUsersString = action.payload;
        }
    },
});

export const Actions = Slice.actions;
export default Slice.reducer;
