import React from "react";
import {useAppSelector} from "@root/Hooks";
import MyTariff from "../Content/MyTariff/MyTariff";
import Loading from "../Content/Loading/Loading";

const ContentController = () => {
    const {error, fetching} = useAppSelector((state) => state.Tariff.myTariff);
    if (fetching) return <Loading />;
    if (error) return null;
    return <MyTariff />;
};

export default ContentController;
