import React from "react";
import styles from "./Footer.module.less";
import {Row, Col} from "antd";
import Title from "@root/Components/Title/Title";
import {Link, animateScroll as scroll} from "react-scroll";
import Logo from "./Images/Logo.svg";
import {Link as RouterLink} from "react-router-dom";
import SberPravo from "./Images/SberPravo.svg";
import routes from "@root/Routes/Routes";

const Footer = () => {
    return (
        <div
            id="Footer"
            className={styles.wrapper}>
            <div className={styles.content}>
                <Row className={styles.row}>
                    <Col className={styles.leftCol}>
                        <div className={styles.legalInfo}>
                            <img 
                                src={Logo} 
                                className={styles.img}
                            />
                            <img
                                src={SberPravo}
                                className={styles.pravoLogo}
                            />
                            <div className={styles.law}>
                                <div>
                                    Данный сайт является демо-стендом, все
                                    <br />
                                    материалы носят информационно-справочный
                                    <br />
                                    характер. По вопросам сотрудничества
                                    <br />
                                    и работы сервиса обращайтесь
                                    <br />
                                    на <a href="mailto:support@sberpravo.ru">support@sberpravo.ru</a>
                                </div>
                                <div>
                                    ОГРН 1187746905004, ИНН 9705124940
                                </div>
                                <div>
                                    Адрес: 117312, г. Москва, ул. Вавилова, д. 19
                                </div>
                                <div>
                                    © ООО «СБЕР ЛИГАЛ». Все права защищены.
                                    <br />
                                    При копировании необходимо упоминание.
                                </div>
                            </div>
                        </div>

                    </Col>
                    <Col className={styles.rightCol}>
                        <div className={styles.links}>
                            <Link
                                className={styles.link}
                                to="AnonymizeIntro"
                                smooth={true}>
                                О продукте
                            </Link>
                            <Link
                                className={styles.link}
                                to="Advantages"
                                smooth={true}>
                                Преимущества
                            </Link>
                            <Link
                                className={styles.link}
                                to="HowWork"
                                smooth={true}>
                                Как это работает?
                            </Link>
                            <Link
                                className={styles.link}
                                to="Tariffs"
                                smooth={true}>
                                Тарифы
                            </Link>
                        </div>
                        <div className={styles.documents}>
                            <RouterLink
                                className={styles.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                to={routes.ld.cookie_politics}>
                                Политика по использованию COOKIES
                            </RouterLink>
                            <RouterLink
                                className={styles.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                to={routes.ld.privacy_policy}>
                                Политика конфиденциальности
                            </RouterLink>
                            <RouterLink
                                className={styles.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                to={routes.ld.personal_data_policy}>
                                Политика обработка персональных данных
                            </RouterLink>
                        </div>
                        <Title 
                            className={styles.title}
                            variant="h2">
                            Заинтересовал наш сервис?
                        </Title>
                        <div className={styles.desc}>
                            Свяжитесь с нами и мы ответим на все вопросы.
                        </div>
                        <div className={styles.contacts}>
                            <a href="tel:+74994041037">+ 7 (499) 404-10-37</a>
                            <a href="mailto:deepdocs@sberpravo.ru">
                                deepdocs@sberpravo.ru
                            </a>
                        </div>
                        <div className={styles.lawDown}>
                            <div className={styles.demo}>
                                Данный сайт является демо-стендом, все
                                <br />
                                материалы носят информационно-справочный
                                <br />
                                характер. По вопросам сотрудничества
                                <br />
                                и работы сервиса обращайтесь
                                <br />
                                на <a href="mailto:support@sberpravo.ru">support@sberpravo.ru</a>
                            </div>
                            <div>
                                ОГРН 1187746905004, ИНН 9705124940
                            </div>
                            <div>
                                Адрес: 117312, г. Москва, ул. Вавилова, д. 19
                            </div>
                            <div>
                                © ООО «СБЕР ЛИГАЛ». Все права защищены.
                                <br />
                                При копировании необходимо упоминание.
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Footer;
