import React from "react";
import styles from "./Tariff.module.less";
import AllTariffs from "../Modules/AllTariffs/AllTariffs";
import MyTariff from "../Modules/MyTariff/MyTariff";

const Tariff = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <MyTariff />
                <AllTariffs />
            </div>
        </div>
    );
};

export default Tariff;
