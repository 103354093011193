import React, {ChangeEvent, ChangeEventHandler} from "react";
import {Search as SearchComponent} from "@root/Components/Controls";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Actions} from "@root/Redux/Tariff/TariffRedux";

const Search = () => {
    const searchUsersString = useAppSelector((state) => state.Tariff.searchUsersString);
    const dispatch = useAppDispatch();
    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(Actions.updateSearchUsersString(e.target.value));
    };
    return <SearchComponent 
        placeholder="Введите email для поиска пользователя"
        defaultValue={searchUsersString}
        maxLength={100} 
        onChange={onSearch}/>;
};

export default Search;
