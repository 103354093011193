import React from "react";
import {DeleteOutlined, LoadingOutlined} from "@ant-design/icons";
import {Button} from "@root/Components/Controls";
import {useAppDispatch, useAppSelector, useGetMinColumnWidthForTable} from "@root/Hooks";
import {TariffUser} from "@root/Types/Tariffs";
import {ColumnType} from "antd/lib/table";
import {v4 as uuid} from "uuid";
import {Actions} from "@root/Redux/Tariff/TariffRedux";

const getRusRole = (role: TariffUser["role"]) => {
    if (role === "admin") return "Владелец";
    if (role === "user") return "Участник";
    return "";
};

const useColumns = ():ColumnType<TariffUser>[] => {
    const dispatch = useAppDispatch();
    const deleteUserFetching = useAppSelector((state) => state.Tariff.deleteUser.fetching);
    return [
        {
            title: "Имя участника",
            dataIndex: "email",
            key: "email",
            showSorterTooltip: false,
            ellipsis: true,
            render: (email) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Имя участника")}}>
                        <span>
                            {email}
                        </span>
                    </div>
                );
            }
        },
        {
            title: "Роль",
            dataIndex: "role",
            key: uuid(),
            showSorterTooltip: false,
            render: (role) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Роль")}}>
                        <span>
                            {getRusRole(role)}
                        </span>
                    </div>
                );
            },
            ellipsis: true,
        },
        {
            title: "",
            dataIndex: "",
            key: uuid(),
            render: (_, record) => {
                if (record.role === "admin") return null;
                if (deleteUserFetching) return <LoadingOutlined />;
                return <Button 
                    onClick={() => {
                        dispatch(Actions.deleteUser({
                            email: record.email
                        }));
                    }}
                    icon={<DeleteOutlined />} 
                    type="link"/>;
            },
        }
    ];
};

export default useColumns;
