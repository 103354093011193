import React, {ChangeEvent, useState} from "react";
import {Button, Modal, StateInputNumber} from "@root/Components/Controls";
import styles from "./AddUsersModal.module.less";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Divider} from "antd";
import {Actions} from "@root/Redux/Tariff/TariffRedux";

type AddUsersModalProps = {
    open: boolean,
    onCancel: () => void
}

const AddUsersModal = (props: AddUsersModalProps) => {
    const [addUsersCount, setAddUsersCount] = useState(1);
    const dispatch = useAppDispatch();
    const myTariff = useAppSelector((state) => state.Tariff.myTariff.data);
    const addUsersFetching = useAppSelector((state) => state.Tariff.addUsers.fetching);
    const pricePerUser = 1000; // В дальнейшем получать это из запроса тарифных планов

    if (!myTariff) return null;

    const onAddUsers = () => {
        dispatch(Actions.addUsers({
            addUsersCount,
            newPrice: (pricePerUser * addUsersCount) + myTariff.next_pay.price,
            onSuccess: () => props.onCancel()
        }));
    };

    const onCancel = () => {
        if (!addUsersFetching) props.onCancel();
    };

    const onChangeUsers = (event: ChangeEvent<HTMLInputElement>) => {
        const numberRegex = /^\d+$/; 
        const value = event.target.value;
        if (!value) setAddUsersCount(0);
        if (!numberRegex.test(value)) return;
        if (Number(value) < 1) setAddUsersCount(0);
        if (Number(value) > 10) setAddUsersCount(10);
        if (Number(value) > 0 && Number(value) < 11) setAddUsersCount(Number(value));
    };

    return (
        <Modal antProps={{
            open: props.open,
            onCancel,
            footer: null,
            title: "Добавить рабочие места",
            width: 440,
            className: styles.modal
        }}>
            <p>
                Выберите количество дополнительных рабочих мест для участников. По окончании платежного периода ваш тарифный план будет автоматически продлен и обновлен.
            </p>
            <StateInputNumber 
                className={styles.inputNumber}
                minusButtonProps={{
                    onClick: () => setAddUsersCount(addUsersCount - 1),
                    disabled: addUsersCount === 0,
                    loading: addUsersFetching
                }}
                inputProps={{
                    min: 1,
                    max: 10,
                    value: addUsersCount,
                    onChange: onChangeUsers
                }}
                plusButtonProps={{
                    onClick: () => setAddUsersCount(addUsersCount + 1),
                    disabled: addUsersCount === 10,
                    loading: addUsersFetching
                }} />
            <Divider type="horizontal" className={styles.divider}/>
            <div className={styles.info}>
                <span>Выш тариф {myTariff.plan.title}</span>
                <span>{myTariff.next_pay.price} ₽ / мес</span>
            </div>
            <div className={styles.subInfo}>
                <span>Следующее списание {myTariff.next_pay.date}</span>
                <span>{Number(myTariff.next_pay.price) + Number(pricePerUser * addUsersCount)} ₽</span>
            </div>
            <Button 
                type="primary"
                onClick={onAddUsers}
                loading={addUsersFetching}
                disabled={addUsersCount === 0}>
                Подтвердить изменение
            </Button>
        </Modal>
    );
};

export default AddUsersModal;
