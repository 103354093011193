import React, {useState} from "react";
import styles from "./AllTariffs.module.less";
import Tariffs from "./Modules/Tariffs/Tariffs";
import {TariffsViewVariants} from "./Types/Types";
import TariffsViewSwitch from "./Components/TariffsViewSwitch/TariffsViewSwitch";
import Title from "./Components/Title/Title";

const AllTariffs = () => {
    const [tariffsViewVariant, setTariffsViewVariant] = useState<TariffsViewVariants>(TariffsViewVariants.corp);

    return (
        <div className={styles.wrapper}>
            <Title />
            <TariffsViewSwitch 
                setTariffsViewVariant={setTariffsViewVariant} 
                tariffsViewVariant={tariffsViewVariant} />
            <Tariffs tariffsViewVariant={tariffsViewVariant}/>
        </div>
    );
};

export default AllTariffs;
