import React, {useState} from "react";
import styles from "./Team.module.less";

import ControlBlock from "./Modules/ControlBlock/ControlBlock";
import TariffUsersTable from "./Modules/TariffUsersTable/TariffUsersTable";
import Search from "./Modules/Search/Search";

const Team = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <ControlBlock />
                <Search />
                <TariffUsersTable />
            </div>
        </div>
    );
};

export default Team;
