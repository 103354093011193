import Request from "../Request";
import Endpoints from "./endpoints";
import {Tariff} from "./types";

export default {
    myTariff: () => {
        return Request.get<Tariff.oMyTariff>(Endpoints.Tariff.my);
    },
    unSubscribe: () => {
        return Request.get<never>(Endpoints.Tariff.unSubscribe);
    },
    addUsers: (params: Tariff.iAddUsers) => {
        return Request.post<never>(Endpoints.Tariff.addUsers, params);
    },
    addPages: (params: Tariff.iAddPages) => {
        return Request.post<never>(Endpoints.Tariff.addPages, params);
    },
    myTariffUsers: () => {
        return Request.post<Tariff.oMyTariffUsers>(Endpoints.Tariff.myTariffUsers);
    },
    myInvites: () => {
        return Request.post<Tariff.oMyInvites>(Endpoints.Tariff.myInvites);
    },
    inviteUser: (params: Tariff.iInviteUser) => {
        return Request.post<never>(Endpoints.Tariff.inviteUser, params);
    },
    deleteUser: (params: Tariff.iDeleteUser) => {
        return Request.post<never>(Endpoints.Tariff.deleteUser, params);
    },
    cancelInvite: (params: Tariff.iCancelInvite) => {
        return Request.post<never>(Endpoints.Tariff.cancelInvite, params);
    }
};
