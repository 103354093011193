import Delete from "@root/Assets/Icons/Delete/Delete";
import DownloadIcon from "@root/Assets/Icons/Download/Download";
import {Button, ButtonIcon} from "@root/Components/Controls";
import Hint from "@root/Components/Hint/Hint";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import React from "react";
import styles from "./AnonymizedDocumentsDropdown.module.less";
import {Anonymization, Documents, Projects} from "@types";
import Actions from "@root/Redux/actions";
import {useHistory} from "react-router-dom";
import getRoute from "@routes";
import Error from "./Assets/Error";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import classnames from "classnames";
import LoadingBadge from "@root/Components/LoadingBadge/LoadingBadge";

type AnonymizedDocumentsDropdownProps = {
    projectId: Projects.Item["id"] | undefined,
    documentId: Documents.Item["id"] | undefined,
}

const AnonymizedDocumentsDropdown = (props: AnonymizedDocumentsDropdownProps) => {
    const anonymizedDocuments = useAppSelector((state) => state.Document.anonymizedDocuments);
    const {projectId, documentId} = props;
    const history = useHistory();
    const dispatch = useAppDispatch();

    const getAnonType = (anon_type: Anonymization.AnonymizationTypes) => {
        if (anon_type === "pdf_anonymization") {
            return "Закрашивание";
        } else if (anon_type === "dict_anonymization") {
            return "Замена из словаря";
        } else if (anon_type === "const_anonymization") {
            return "Замена константами";
        } else {
            return "Тип отсутствует";
        }
    };

    const setPrevRoute = () => {
        sessionStorage.setItem("prevRoute", history.location.pathname);
    };

    const onDownload = (id: Documents.AnonymizedDocument["anonymized_document"]["id"]) => {
        dispatch(Actions.Anonymize.downloadAnonymizeDocumentsResult({
            anonymized_documents_ids: [id]
        }));
    };

    const onClick = (id: Documents.AnonymizedDocument["anonymized_document"]["id"]) => {
        setPrevRoute();
        history.push(getRoute.lk.project.anonymize(
            projectId,
            documentId,
            id
        ));
    };

    const onDelete = (id: Documents.AnonymizedDocument["id"]) => {
        dispatch(Actions.Document.deleteAnonymizedDocuments({
            anonymization_request_ids: [id]
        }));
    };
    
    if (anonymizedDocuments.fetching) {
        return null;
    }

    if (anonymizedDocuments.error) {
        return (
            <div className={styles.error}>
                <Icon component={Error} />
                Произошла ошибка загрузки документов, попробуйте еще раз
            </div>
        );
    }

    if (anonymizedDocuments.loaded) return (
        <div
            className={styles.content}
            onClick={(e) => e.stopPropagation()}>
            <span className={styles.title}>Обезличенные версии<div className={styles.count}>{anonymizedDocuments.data?.length}</div></span>
            {anonymizedDocuments.data?.length === 0 && (
                <span className={styles.emptyDesc}>У вас пока нет обезличенных документов</span>
            )}
            {anonymizedDocuments.data && anonymizedDocuments.data?.length > 0 && (
                <div className={styles.menu}>
                    {anonymizedDocuments.data.map((item, index) => (
                        <div key={item.id} className={styles.menuItem}>
                            <div className={styles.menuItemButtonGroup}>
                                <Button
                                    className={classnames(styles.menuItemButtonGroupButton, {[styles.menuItemButtonGroupButtonError]: item.anonymization_status === 200})}
                                    disabled={item.anonymization_status !== 200}
                                    onClick={() => onClick(item.anonymized_document.id)}
                                    type="link">
                                    Вариант {index + 1}
                                </Button>
                                <Hint
                                    color={item.anonymization_status === 400 ? "#F86262" : undefined}
                                    title={
                                        <div className={styles.hintContent}>
                                            {item.anonymization_status === 400 && (
                                                <span className={styles.hintError}>При обезличивании произошла ошибка</span>
                                            )}
                                            {item.anonymization_status === 100 && (
                                                <span className={styles.hintInfo}>
                                                    <LoadingOutlined /> Документ обезличивается
                                                </span>
                                            )}
                                            <span className={styles.hintTitle}>{getAnonType(item.anonymization_type)}:</span>
                                            <span>{item.entities.join(", ")}</span>
                                        </div>
                                    }/>
                            </div>
                            <div className={styles.menuItemActionButtons}>
                                <ButtonIcon
                                    className={styles.menuItemActionButtonsButton}
                                    type="link"
                                    disabled={item.anonymization_status !== 200}
                                    onClick={() => onDownload(item.anonymized_document.id)}
                                    icon={item.anonymization_status !== 200 ? <LoadingBadge type="anonymization" /> : <DownloadIcon />}
                                />
                                <ButtonIcon
                                    className={styles.menuItemActionButtonsButton}
                                    onClick={() => onDelete(item.id)}
                                    type="link"
                                    icon={<Delete />}
                                />
                            </div>

                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    return null;

};

export default AnonymizedDocumentsDropdown;
