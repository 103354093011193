import React, {useEffect} from "react";
import Title from "@components/Title/Title";
import styles from "./Settings.module.less";
import useTitle from "@root/Hooks/useTitle/useTitle";
import Content from "./Content/Content";
import {useAppDispatch} from "@root/Hooks";
import Actions from "@root/Redux/actions";

const Settings = () =>  {
    useTitle("DeepDocs - Настройки");
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(Actions.Tariff.myTariff({}));
        dispatch(Actions.Common.getTariffs());
        dispatch(Actions.Tariff.myTariffUsers({}));
    }, []);

    return (
        <div className={styles.wrapper}>
            <Title
                className={styles.title}
                variant="h2">
                    Настройки
            </Title>
            <Content />
        </div>

    );
};

export default Settings;
