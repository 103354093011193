import React from "react";
import styles from "./TariffsViewSwitch.module.less";
import {Radio} from "antd";
import Hint from "@root/Components/Hint/Hint";
import {TariffsViewVariants} from "../../Types/Types";

type TariffsViewSwitchProps = {
    tariffsViewVariant: TariffsViewVariants
    setTariffsViewVariant: React.Dispatch<React.SetStateAction<TariffsViewVariants>>
}

const TariffsViewSwitch = (props: TariffsViewSwitchProps) => {
    const {tariffsViewVariant, setTariffsViewVariant} = props;
    return (
        <Radio.Group
            className={styles.radio}
            onChange={(event) => setTariffsViewVariant(event.target.value)}
            value={tariffsViewVariant} 
            optionType="button">
            <Radio.Button 
                value={TariffsViewVariants.corp} 
                className={styles.radioButton}>
                    Корпоративный
            </Radio.Button>
            <Radio.Button 
                value={TariffsViewVariants.pers} 
                className={styles.radioButton} 
                disabled>
                <span className={styles.radioDisabled}>
                    Личный
                    <Hint
                        title={"В данный момент доступны только корпоративные тарифы. Тарифы для физических лиц появятся совсем скоро – следите за обновлениями!"}
                        placement="right"/>
                </span>
            </Radio.Button>
        </Radio.Group>
    );
};

export default TariffsViewSwitch;
