import Table from "@root/Components/Controls/Table/Table";
import styles from "./TariffUsersTable.module.less";
import React from "react";
import {useAppSelector} from "@root/Hooks";
import useColumns from "./Hooks/useColumns";
import Loading from "./Components/Loading/Loading";
import useFilteredTariffUsers from "./Hooks/useFilteredTariffUsers";

const TariffUsersTable = () => {
    const filteredTariffUsers = useFilteredTariffUsers();
    const fetchingTariffUsers = useAppSelector((state) => state.Tariff.myTariffUsers.fetching);

    const columns = useColumns();

    if (fetchingTariffUsers) return <Loading />; 

    return (
        <Table
            dataSource={filteredTariffUsers}
            columns={columns}
            rowKey="email"
            size="small"
            className={styles.table}
            pagination={false}
            locale={{
                emptyText: "Пользователей с таким email нет"
            }}
            scroll={{
                y: "calc(100vh - 500px)",
                x: true,
            }}/>
    );
};

export default TariffUsersTable;
