import React from "react";
import {Button, Input, FormItem} from "@root/Components/Controls";
import {Form} from "antd";
import {User as UserApi} from "@root/Api/UserApi/types";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import {User} from "@types";
import {changePasswordRules, passwordRules} from "@root/Utils/Form/Rules";

const {isAuthUserInfo} = User.Guard.User;

const PasswordChangeForm = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const passwordChange = useAppSelector((state) => state.User.changePassword);
    const userInfo = useAppSelector((state) => state.User.info.data);

    if (!isAuthUserInfo(userInfo)) return null;

    const onSuccess = () => {
        form.resetFields();
    };

    const onSubmit = (values: UserApi.iChangePassword) => {
        dispatch(Actions.User.changePassword({
            ...values,
            onSuccess,
        }));
    };
    
    return (
        <Form
            form={form}
            name="changepassword"
            layout="vertical"
            onFinish={onSubmit}>
            <FormItem
                name="old_password"
                label="Текущий пароль"
                rules={passwordRules}>
                <Input.Password
                    disabled={passwordChange.fetching}
                    placeholder="Введите пароль"
                />
            </FormItem>
            <FormItem
                name="new_password"
                label="Новый пароль"
                dependencies={["old_password"]}
                rules={changePasswordRules.newPassword}>
                <Input.Password
                    disabled={passwordChange.fetching}
                    placeholder="Введите пароль"
                />
            </FormItem>
            <FormItem
                name="confirm"
                label="Повторите новый пароль"
                dependencies={["password"]}
                rules={changePasswordRules.confirmPassword}>
                <Input.Password
                    disabled={passwordChange.fetching}
                    placeholder="Повторите пароль"
                />
            </FormItem>
            <FormItem>
                <Button
                    size="large"
                    type="primary"
                    loading={passwordChange.fetching}
                    htmlType="submit">
                        Сменить пароль
                </Button>
            </FormItem>
        </Form>
    );
};

export default PasswordChangeForm;
