import styles from "./Title.module.less";
import React from "react";
import TitleComponent from "@root/Components/Title/Title";

const Title = () => {
    return (
        <TitleComponent
            variant="h2"
            className={styles.title}>
            Тарифы
        </TitleComponent>
    );
};

export default Title;
