import React from "react";
import styles from "./Controls.module.less";
import AddPages from "./Modules/AddPages/AddPages";
import AddUsers from "./Modules/AddUsers/AddUsers";
import UnSubscribe from "./Modules/UnSubscribe/UnSubscribe";
import {useAppSelector} from "@root/Hooks";

const Controls = () => {
    const data = useAppSelector((state) => state.Tariff.myTariff.data);
    if (!data || !data.plan.active) return null;
    return (
        <div className={styles.wrapper}>
            <AddPages />
            <AddUsers />
            <UnSubscribe />
        </div>
    );
};

export default Controls;
