import React, {useState, useEffect} from "react";
import {Button} from "@components/Controls";
import styles from "./CookiesAgreement.module.less";
import {Link} from "react-router-dom";
import routes from "@root/Routes/Routes";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const CookiesAgreement = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const value = cookies.get("cookies_agreement");
        if (value !== "true") setVisible(true);
    }, []);
    
    if (!visible) return null;

    return (
        <div className={styles.wrapper}>

            <div className={styles.container}>
                <div className={styles.frame}>
                    <p className={styles.text}>
                        Сервис DeepDocs использует cookie (файлы с&nbsp;данными о&nbsp;прошлых посещениях сайта) 
                        для персонализации сервисов и&nbsp;удобства пользователей. Пожалуйста, ознакомьтесь 
                        с&nbsp; <Link to={routes.ld.cookie_politics} target="_blank" rel="noopener noreferrer">Политикой использования cookies</Link>. 
                        Вы&nbsp;можете запретить сохранение cookie в&nbsp;настройках своего браузера.
                    </p>
                    <div className={styles.control}>
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => {
                                cookies.set(
                                    "cookies_agreement",
                                    "true",
                                    {
                                        path: "/"
                                    }
                                );
                                setVisible(false);
                            }}>
                                Принять
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiesAgreement;
