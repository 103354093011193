import React from "react";
import styles from "./PersonalDataPolicy.module.less";

const PersonalDataPolicy = () => {
    return (
        <div className={styles.personalDataPolicy}>
            <h2>
                ПОЛИТИКА Общества с ограниченной ответственностью «СБЕР ЛИГАЛ» в отношении обработки персональных данных
            </h2>
            <hr />
            <h2>
                1. Термины и определения
            </h2>
            <p>
                В Политике Общества с ограниченной ответственностью «СБЕР ЛИГАЛ» в отношении обработки персональных данных (далее – Политика) используются следующие термины и определения:
            </p>
            <p>
                1.1. <strong>Персональные данные</strong> – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных). К субъектам персональных данных могут быть отнесены, в том числе:
            </p>
            <ul>
                <li>работники ООО «СБЕР ЛИГАЛ», бывшие работники ООО «СБЕР ЛИГАЛ», кандидаты на замещение вакантных должностей в ООО «СБЕР ЛИГАЛ», а также родственники работников ООО «СБЕР ЛИГАЛ»;</li>
                <li>заказчики и контрагенты ООО «СБЕР ЛИГАЛ» и их представители.</li>
            </ul>
            <p>
                1.2. <strong>Оператор</strong> – ООО «СБЕР ЛИГАЛ», самостоятельно или совместно с другими лицами организующее и (или) осуществляющее обработку персональных данных, а также определяющее цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
            </p>
            <p>
                1.3.<strong>Обработка персональных данных</strong> – любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без использования таких средств. К таким действиям (операциям) относятся: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
            </p>
            <p>
                1.4. <strong>Автоматизированная обработка персональных данных </strong>– обработка персональных данных с помощью средств вычислительной техники.
            </p>
            <p>
                1.5. <strong>Биометрические персональные данные</strong> – сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность и которые используются оператором для установления личности субъекта.
            </p>
            <p>
                1.6. <strong>Блокирование персональных данных</strong> – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).
            </p>
            <p>
                1.7. <strong>Обезличивание персональных данных</strong> – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.
            </p>
            <p>
                1.8. <strong>Предоставление персональных данных</strong> – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.
            </p>
            <p>
                1.9. <strong>Распространение персональных данных</strong> – действия, направленные на раскрытие персональных данных неопределенному кругу лиц.
            </p>
            <p>
                1.10. <strong>Уничтожение персональных данных</strong> – действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.
            </p>
            <h2>2. Общие положения</h2>
            <p>
                2.1. Общество с ограниченной ответственностью «СБЕР ЛИГАЛ» (далее – Общество) в рамках выполнения своей деятельности осуществляет обработку персональных данных и является оператором персональных данных.
            </p>
            <p>
                2.2. Политика разработана в соответствии с требованиями законодательства Российской Федерации в сфере защиты персональных данных, в том числе Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных данных» (далее – Федеральный закон «О персональных данных»).
            </p>
            <p>
                2.3. Политика определяет общие принципы, цели, способы и порядок обработки персональных данных (далее – ПДн) в Обществе, а также меры по обеспечению безопасности при их обработке.
            </p>
            <p>
                2.4. Политика подлежит актуализации в случае изменения законодательства Российской Федерации в сфере ПДн, а также в случае изменения процессов обработки ПДн и применяемых мер защиты ПДн в Обществе.
            </p>
            <p>
                2.5. Правовым основанием обработки персональных данных является совокупность правовых актов (в том числе согласие работника Общества на обработку, хранение и передачу его персональных данных третьей стороне и согласие субъекта персональных данных на обработку его персональных данных, а также договоры, заключаемые между Обществом и субъектами персональных данных), во исполнение которых и в соответствии с которыми Оператор осуществляет обработку ПДн.
            </p>
            <h2>
                3. Принципы обработки персональных данных
            </h2>
            <p>
                3.1. Обработка ПДн осуществляется Обществом на законной и справедливой основе и ограничивается достижением конкретных, заранее определенных и законных целей.
            </p>
            <p>
                3.2. Не допускается обработка ПДн, несовместимая с целями их сбора, объединение баз данных, содержащих Пдн, обработка которых осуществляется в целях, несовместимых между собой.
            </p>
            <p>
                3.3. Обработке подлежат только ПДн, которые отвечают целям их обработки, определенным и заявленным при сборе ПДн, а также полномочиям Общества.
            </p>
            <p>
                3.4. Содержание и объём обрабатываемых Обществом ПДн соответствуют заявленным целям обработки, избыточность обрабатываемых данных не допускается.
            </p>
            <p>
                3.5. При обработке ПДн обеспечивается точность ПДн, их достаточность и в необходимых случаях актуальность по отношению к целям обработки ПДн.
            </p>
            <p>
                3.6. В Обществе принимаются необходимые меры по удалению или уточнению неполных, или неточных ПДн.
            </p>
            <p>
                3.7. Хранение ПДн осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки ПДн, если срок хранения ПДн не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.
            </p>
            <p>
                3.8. Обрабатываемые персональные данные подлежат уничтожению или обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.
            </p>
            <p>
                3.9. В Обществе допускается обработка ПДн, полученных из общедоступных источников ПДн или сделанных общедоступными с письменного согласия субъекта персональных данных.
            </p>
            <p>
                3.10. Обработка специальных категорий персональных данных в Обществе не осуществляется.
            </p>
            <p>
                3.11. Общество осуществляет передачу ПДн субъектов персональных данных третьим лицам в соответствии с требованиями законодательства Российской Федерации в сфере обработки и защиты персональных данных.
            </p>
            <p>
                3.12. Общество вправе поручить обработку персональных данных другим лицам в соответствии со статьей 6 Федерального закона «О персональных данных».
            </p>
            <p>
                3.13. В случаях поручения обработки ПДн другому лицу, Общество заключает договор с этим лицом и получает согласие от субъектов персональных данных, если иное не предусмотрено Федеральным законом «О персональных данных». Лицо, осуществляющее обработку ПДн по поручению Общества, обязано соблюдать принципы и правила обработки ПДн, предусмотренные Федеральным законом «О персональных данных».
            </p>
            <p>
                3.14. В случаях, когда Общество поручает обработку ПДн другому лицу, ответственность перед субъектом персональных данных за действия указанного лица несет Общество. Лицо, осуществляющее обработку ПДн по поручению Общества, несет ответственность перед Обществом.
            </p>
            <p>
                3.15. В Обществе может осуществляться как автоматизированная обработка ПДн (обработка персональных данных с помощью средств автоматизации), так и неавтоматизированная обработка ПДн (обработка персональных данных без использования средств автоматизации – обработка ПДн, при которой действия с ПДн осуществляются при непосредственном участии человека).
            </p>
            <p>
                3.16. Сроки обработки (в том числе хранения) ПДн, обрабатываемых Обществом, определяются исходя из целей обработки персональных данных и в соответствии с требованиями законодательства Российской Федерации.
            </p>
            <h2>
                4. Цели обработки персональных данных
            </h2>
            <p>
                4.1. В Обществе обрабатываются ПДн субъектов персональных данных, в целях выполнения возложенных законодательством Российской Федерации на Общество функций, полномочий и обязанностей.
            </p>
            <h2>
                5. Права субъектов персональных данных
            </h2>
            <p>
                5.1. Субъект персональных данных имеет право на получение информации, касающейся обработки его ПДн, в том числе содержащей:
            </p>
            <ul>
                <li>подтверждение факта обработки ПДн Обществом;</li>
                <li>правовые основания и цели обработки ПДн;</li>
                <li>цели и применяемые Обществом способы обработки ПДн;</li>
                <li>наименование и место нахождения Общества, сведения о лицах (за исключением работников Общества), которые имеют доступ к ПДн или которым могут быть раскрыты ПДн на основании договора с Обществом или на основании Федерального закона «О персональных данных»;</li>
                <li>обрабатываемые ПДн, относящиеся к соответствующему субъекту персональных данных, источник их получения, если иной порядок представления таких данных не предусмотрен Федеральным законом «О персональных данных»;</li>
                <li>сроки обработки ПДн, в том числе сроки их хранения;</li>
                <li>порядок осуществления субъектом персональных данных прав, предусмотренных Федеральным законом «О персональных данных»;</li>
                <li>информацию об осуществленной или о предполагаемой трансграничной передаче данных;</li>
                <li>наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку ПДн по поручению Общества, если обработка поручена или будет поручена такому лицу;</li>
                <li>иные сведения, предусмотренные Федеральным законом «О персональных данных» или другими федеральными законами.</li>
            </ul>
            <p>
                5.2. Субъект персональных данных вправе требовать от Общества уточнения его ПДн, их блокирования или уничтожения в случае, если ПДн являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.
            </p> 
            <p>
                5.3. Субъект персональных данных имеет право на отзыв согласия на обработку ПДн, в случае если согласие было дано Обществу.
            </p> 
            <p>
                5.4. Право доступа субъекта персональных данных к своим персональным данным может быть ограничено в соответствии с законодательством Российской Федерации.
            </p> 
            <p>
                5.5. Субъект персональных данных обладает также иными правами, предусмотренными законодательством Российской Федерации.
            </p>
            <h2>
                6. Обязанности Общества
            </h2>
            <p>
                6.1. При обработке ПДн Общество обязано:
            </p>
            <p>
                6.1.1. Предоставить субъекту персональных данных по его просьбе следующую информацию:
            </p>
            <ul>
                <li>подтверждение факта обработки ПДн Обществом;</li>
                <li>правовые основания и цели обработки ПДн;</li>
                <li>применяемые Обществом способы обработки ПДн;</li>
                <li>наименование и местонахождение Общества;</li>
                <li>сведения о лицах (за исключением работников Общества), которые имеют доступ к ПДн или которым могут быть раскрыты ПДн на основании договора с Обществом, на основании Федерального закона «О персональных данных» или иных законных основаниях;</li>
                <li>обрабатываемые ПДн, относящиеся к соответствующему субъекту персональных данных, источник их получения, если иной порядок представления таких данных не предусмотрен Федеральным законом «О персональных данных»;</li>
                <li>сроки обработки ПДн, в том числе сроки их хранения;</li>
                <li>порядок осуществления субъектом персональных данных прав, предусмотренных Федеральным законом «О персональных данных»;</li>
                <li>информацию об осуществленной или о предполагаемой трансграничной передаче ПДн;</li>
                <li>наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку ПДн по поручению Общества, если обработка поручена или будет поручена такому лицу;</li>
                <li>иные сведения, предусмотренные Федеральным законом «О персональных данных» или другими федеральными законами.</li>
            </ul>
            <p>
                6.1.2. Разъяснить субъекту персональных данных юридические последствия его отказа предоставить Обществу ПДн, если предоставление ПДн является обязательным в соответствии с Федеральным законом «О персональных данных».
            </p> 
            <p>
                6.1.3. Обеспечить запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение ПДн с использованием баз данных, находящихся на территории РФ.
            </p> 
            <p>
                6.1.4. Предоставить субъекту персональных данных (если ПДн получены не от субъекта персональных данных, до начала обработки таких ПДн) следующую информацию (за исключением случаев, предусмотренных Политикой):
            </p>
            <ul>
                <li>наименование и адрес Общества или его представителя;</li>
                <li>цель обработки ПДн и ее правовое основание;</li>
                <li>предполагаемых пользователей ПДн;</li>
                <li>установленные Федеральным законом «О персональных данных» права субъекта персональных данных;</li>
                <li>источник получения ПДн.</li>
            </ul>
            <p>
                6.2. Общество освобождается от обязанности предоставить субъекту персональных данных сведения, предусмотренные п. 6.1.4. Политики, в случаях если:
            </p>
            <ul>
                <li>субъект персональных данных уведомлен об осуществлении обработки его ПДн Обществом;</li>
                <li>ПДн получены Обществом на основании Федерального закона «О персональных данных» или в связи с исполнением договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных;</li>
                <li>ПДн сделаны общедоступными субъектом персональных данных или получены из общедоступного источника.</li>
            </ul>
            <p>
                6.3. Общество обязано не раскрывать третьим лицам и не распространять ПДн без согласия субъекта персональных данных за исключением случаев, предусмотренных законодательством Российской Федерации.
            </p>
            <p>
                6.4. Общество обязано опубликовать или иным образом обеспечить неограниченный доступ к Политике.
            </p>
            <h2>
                7. Порядок обработки персональных данных
            </h2>
            <p>
                7.1 Обработка ПДн в Обществе производится работниками Общества. В соответствии законодательством Российской Федерации Общество вправе передать свои полномочия по обработке ПДн другой организации.
            </p>
            <p>
                7.2. Обработка ПДн в Обществе включает в себя следующие действия: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение ПДн.
            </p>
            <p>
                7.3. Обработка ПДн субъектов персональных данных осуществляется как на бумажных носителях, так и с использованием средств автоматизации (с помощью средств вычислительной техники) путем:
            </p>
            <ul>
                <li>получения оригиналов необходимых документов;</li>
                <li>копирования оригиналов документов;</li>
                <li>внесения сведений в учетные формы на бумажных и электронных носителях;</li>
                <li>внесения ПДн в базы данных и информационные системы Общества.</li>
            </ul>
            <p>
                7.4. Сбор, запись, систематизация, накопление (обновление, изменение) ПДн осуществляется путем получения ПДн непосредственно от субъектов персональных данных.
            </p> 
            <p>
                7.5. Обращения субъектов персональных данных (и их представителей) рассматриваются в Обществе в соответствии с законодательством Российской Федерации и принятыми в соответствие с ним локальными нормативными актами Общества.
            </p> 
            <p>
                7.6. При передаче персональных данных субъекта персональных данных, работники Общества, осуществляющие обработку ПДн, должны соблюдать следующие требования:
            </p>
            <ul>
                <li>не сообщать ПДн субъекта персональных данных третьей стороне без письменного согласия субъекта, за исключением случаев, когда это необходимо в целях предупреждения угрозы жизни и здоровью субъекта персональных данных, а также в случаях, установленных законодательством Российской Федерации;</li>
                <li>не сообщать персональные данные субъекта персональных данных в коммерческих целях без его письменного согласия;</li>
                <li>предупредить лиц, получающих ПДн субъекта персональных данных о том, что эти данные могут быть использованы лишь в целях, для которых они сообщены, и требовать от этих лиц подтверждения того, что правило соблюдено. Лица, получающие ПДн субъекта персональных данных, обязаны соблюдать режим конфиденциальности;</li>
                <li>разрешать доступ к ПДн субъекта персональных данных только лицам, определенным приказом Обществом, при этом указанные лица должны иметь право получать только те ПДн, которые необходимы для выполнения конкретных своих функций;</li>
                <li>не запрашивать информацию о состоянии здоровья субъекта персональных данных;</li>
                <li>передавать ПДн субъекта персональных данных представителям субъекта персональных данных в порядке, установленном законодательством Российской Федерации, и ограничить эту информацию только теми ПДн субъекта персональных данных, которые необходимы для выполнения указанными представителями их функций.</li>
            </ul>
            <p>
                7.7. По достижении цели обработки ПДн обработка ПДн в Обществе прекращается и эти ПДн уничтожаются.
            </p>
            <p>
                7.8. В случае отзыва субъектом персональных данных своего согласия на обработку ПДн и в случае, если сохранение ПДн более не требуется для целей обработки ПДн, Общество прекращает их обработку и уничтожает ПДн или обеспечивает их уничтожение (если обработка ПДн осуществляется другим лицом, действующим по поручению Общества) в срок, не превышающий тридцати дней с даты, поступления отзыва.
            </p>
            <p>
                7.9. По запросу субъекта персональных данных или его представителя Обществом сообщается информация о наличии ПДн, относящихся к субъекту. По запросу субъекта персональных данных или его представителя Общество знакомит субъекта персональных данных или его представителя с этими ПДн в течение тридцати дней с даты, получения запроса.
            </p>
            <p>
                7.10. Хранение и защита ПДн, как на бумажных, так и на электронных носителях информации, осуществляется в порядке, исключающем их утрату или их неправомерное использование, приведенном в Положении об обработке ПДн Общества.
            </p>
            <p>
                7.11. Не допускается отвечать на вопросы, связанные с передачей ПДн по телефону, факсу, электронной почте, за исключением случаев, описанных в Положении об обработке ПДн Общества.
            </p>
            <h2>
                8. Меры по обеспечению безопасности персональных данных, принимаемые Обществом
            </h2>
            <p>
                8.1. Общество при обработке ПДн принимает необходимые правовые, организационные и технические меры или обеспечивает их принятие для защиты ПДн от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения ПДн, а также от иных неправомерных действий в отношении ПДн.
            </p>
            <p>
                8.2. В Обществе приняты следующие меры безопасности:
            </p>
            <ul>
                <li>назначено лицо, ответственное за организацию обработки ПДн;</li>
                <li>изданы локальные нормативные акты Общества по вопросам обработки ПДн;</li>
                <li>используется необходимый и достаточный перечень средств защиты информации для защиты ПДн, причем в качестве средств защиты информации используются средства защиты информации, прошедшие в установленном порядке процедуру оценки соответствия требованиям законодательства Российской Федерации в области обеспечения безопасности информации;</li>
                <li>проводится внутренний контроль соблюдения законодательства Российской Федерации о персональных данных, в том числе требований к защите персональных данных;</li>
                <li>работники Общества ознакомлены с положениями законодательства Российской Федерации о персональных данных, локальными нормативными актами Общества по вопросам обработки ПДн, требованиями к защите персональных данных.</li>
            </ul>
        </div>
    );
};

export default PersonalDataPolicy;
