import routes from "@root/Routes/Routes";
import React from "react";
import {useLocation} from "react-router-dom";
import CookiePolitics from "../Content/Documents/СookiePolitics/CookiePolitics";
import PrivacyPolicy from "../Content/Documents/PrivacyPolicy/PrivacyPolicy";
import PersonalDataPolicy from "../Content/Documents/PersonalDataPolicy/PersonalDataPolicy";

const ContentController = () => {
    const location = useLocation();

    if (location.pathname === routes.ld.cookie_politics) {
        return (
            <CookiePolitics />
        );
    }
    if (location.pathname === routes.ld.privacy_policy) {
        return (
            <PrivacyPolicy />
        );
        
    }
    if (location.pathname === routes.ld.personal_data_policy) {
        return (
            <PersonalDataPolicy />
        ); 
    }

    return null;
    
};

export default ContentController;
