import moment from "moment";

type getDateFullProps = {
    date: string;
}

const momentFormat = "DD.MM.YYYY HH:mm:ss";

const getDateFull = (props: getDateFullProps) => {
    const {date} = props;
    if (date === "Данные отсутствуют" || date === "-") {
        return date;
    } else {
        return moment(date).format(momentFormat);
    }
};

export default getDateFull;
