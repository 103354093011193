import React from "react";
import styles from "./Badge.module.less";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

type BadgeProps = {
    active: boolean
}

const Badge = (props: BadgeProps) => {
    if (props.active) return <CheckOutlined className={styles.active}/>;
    return <CloseOutlined className={styles.inactive}/>;
};

export default Badge;
