import React, {useEffect} from "react";
import {Button, FormItem, Input, Modal} from "@components/Controls";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import CloseIcon from "@assets/Icons/Close/Close";
import styles from "./EditModal.module.less";
import GridContainer from "@components/GridContainer/GridContainer";
import {Form} from "antd";
import {titleRules} from "@utils/Form/Rules";

const EditModal = () => {
    const editModal = useAppSelector((state) => state.Projects.editModal);
    const selectedProject = useAppSelector((state) => state.Projects.selectedProject.data);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const onCloseModal = () => {
        dispatch(Actions.Projects.setEditModal(false));
    };

    const onCreate = () => {
        if (!selectedProject) return null;
        const data = {
            id: selectedProject.id,
            title: form.getFieldValue("title"),
            description: form.getFieldValue("description"),
        };
        dispatch(Actions.Projects.changeProjectInfo(data));
    };

    useEffect(() => {
        return () => form.resetFields();
    }, [editModal, form]);

    if (!selectedProject) return null;

    return (
        <Modal
            antProps={{
                destroyOnClose: true,
                open: editModal,
                title: "Редактировать проект",
                footer: null,
                onCancel: onCloseModal,
                closable: true,
                width: 440,
                getContainer: false,
                closeIcon: <CloseIcon />,
                centered: true,
                forceRender: true,
            }}>
            <GridContainer className={styles.grid}>
                <Form
                    name="EditProject"
                    layout="vertical"
                    form={form}
                    requiredMark={false}
                    onFinish={onCreate}>
                    <FormItem
                        name="title"
                        initialValue={selectedProject.title}
                        rules={titleRules}
                        label="Название проекта">
                        <Input
                            placeholder={"Введите название"}
                            allowClear
                        />
                    </FormItem>
                    <FormItem
                        className={styles.inputProjectDescription}
                        name="description"
                        initialValue={selectedProject.description}
                        label="Описание проекта">
                        <Input.TextArea
                            showCount
                            maxLength={512}
                            autoSize={{
                                minRows: 3,
                                maxRows: 3,
                            }}
                            placeholder={"Небольшая справка по проекту..."}
                        />
                    </FormItem>
                    <FormItem className={styles.lastFormItem}>
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            className={styles.button}>
                            Сохранить изменения
                        </Button>
                    </FormItem>
                </Form>
            </GridContainer>
        </Modal>
    );
};

export default EditModal;
