import React, {useEffect} from "react";
import {Button, Modal, Input, FormItem} from "@root/Components/Controls";
import styles from "./InviteUserModal.module.less";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Form} from "antd";
import {emailRules} from "@utils/Form/Rules";
import {Actions} from "@root/Redux/Tariff/TariffRedux";

type InviteUserModalProps = {
    open: boolean,
    onCancel: () => void
}

type FormFields = {
    email: string
}

const InviteUserModal = (props: InviteUserModalProps) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const inviteUserFetching = useAppSelector((state) => state.Tariff.inviteUser.fetching);

    const onCancel = () => {
        if (!inviteUserFetching) props.onCancel();
    };

    const onInviteUser = (props: FormFields) => {
        dispatch(Actions.inviteUser({
            email: props.email,
            onSuccess: () => onCancel()
        }));
    };
    
    useEffect(() => {
        form.resetFields();
    }, [props.open]);

    return (
        <Modal antProps={{
            open: props.open,
            onCancel,
            footer: null,
            title: "Добавить участников",
            width: 440,
            className: styles.modal,
        }}>
            <Form
                form={form}
                name="inviteUser"
                onFinish={onInviteUser}
                layout="vertical"
                scrollToFirstError>
                <FormItem
                    name="email"
                    className={styles.item}
                    label="e-mail участника"
                    rules={emailRules}>
                    <Input
                        allowClear
                        className={styles.input}
                        placeholder="Введите e-mail куда отправить приглашение" 
                        disabled={inviteUserFetching}/>
                </FormItem>
                <div className={styles.controls}>
                    <FormItem>
                        <Button
                            loading={inviteUserFetching}
                            size="large"
                            type="primary"
                            className={styles.button}
                            htmlType="submit">
                            Пригласить участников
                        </Button>
                    </FormItem>
                </div>
            </Form>
        </Modal>
    );
};

export default InviteUserModal;
