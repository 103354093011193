import React, {useEffect, useState} from "react";
import styles from "./ResultDropdown.module.less";
import {Popover} from "antd";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import Button from "../../Components/Button/Button";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@root/Redux/actions";
import AnonymizedDocumentsDropdown from "@root/Modules/AnonymizedDocumentsDropdown/AnonymizedDocumentsDropdown";
import PreviousAnonymizeIcon from "./Icons/PreviousAnonymize";

const ResultDropdown = () => {
    const [popover, setPopover] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const project = useAppSelector((state) => state.Projects.selectedProject.data);
    const openedDocument = useAppSelector((state) => state.Project.openedDocument.data);
    const [loading, setLoading] = useState<boolean>(false);
    const anonymizedDocuments = useAppSelector((state) => state.Document.anonymizedDocuments);
    const [isMounted, setIsMounted] = useState<boolean>(true);

    if (!openedDocument) return null;

    const handlePopoverVisible = (visible: boolean) => {
        setPopover(visible);
    };

    const onOpenChange = (visible: boolean) => {
        if (visible) {
            setLoading(true);
            dispatch(Actions.Document.getAnonymizedDocuments({
                document_id: openedDocument.id,
                onSuccess: () => {
                    if (isMounted) {
                        handlePopoverVisible(visible);
                        setLoading(false);
                    }

                },
                onError: () => {
                    isMounted && setLoading(false);
                }
            }));
        }
        if (!visible) {
            dispatch(Actions.Document.stopGetAnonymizedDocuments());
            dispatch(Actions.Document.eraseAnonymizedDocuments());
            handlePopoverVisible(visible);
        }
    };

    useEffect(() => {
        return () => setIsMounted(false);
    }, []);

    return (
        <Popover
            key="more"
            content={<AnonymizedDocumentsDropdown projectId={project?.id} documentId={openedDocument.id}/>}
            trigger="click"
            open={popover}
            onOpenChange={onOpenChange}
            placement="bottomLeft"
            overlayClassName={styles.popover}>
            <Button
                disabled={anonymizedDocuments.fetching}
                icon={loading ? <LoadingOutlined /> : <Icon component={PreviousAnonymizeIcon} />}>
                Результаты обезличивания
            </Button>
        </Popover>
    );
};

export default ResultDropdown;
