import {RuleObject} from "antd/lib/form";

export default [() => ({
    validator(_rule: RuleObject, value: any) {
        if (!value) {
            return Promise.reject(new Error("Это поле не может быть пустым."));
        }
        if (value.length > 100) {
            return Promise.reject("Пароль должен содержать не более 100 символов");
        }
        return Promise.resolve();
    },
})];
