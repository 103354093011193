import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {iActions} from "@root/Redux/Tariff/types";
import {PayloadAction} from "@reduxjs/toolkit";
import {delay} from "redux-saga/effects";
import {TariffUser} from "@root/Types/Tariffs";

const mockData: TariffUser[] = [
    {
        email: "user11@example.com",
        role: "admin"
    },
    {
        email: "user12@example.com",
        role: "user"
    },
    {
        email: "user13@example.com",
        role: "user"
    },
    {
        email: "user14@example.com",
        role: "user"
    },
    {
        email: "user15@example.com",
        role: "user"
    },
    {
        email: "user16@example.com",
        role: "user"
    },
    
];

const MyTariffUsers = function* (action: PayloadAction<iActions.myTariff>) {
    const {onError, onSuccess} = action.payload;
    try {
        yield delay(1000); // УДАЛИТЬ 
        // const {data} = yield* call(Api.Tariff.myTariffUsers);
        onSuccess && onSuccess();
        // yield* put(Actions.Tariff._myTariffUsersSuccess(data));
        yield* put(Actions.Tariff._myTariffUsersSuccess(mockData));
    } catch (error) {
        onError && onError();
        yield* put(Actions.Tariff._myTariffUsersError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Tariff.myTariffUsers, MyTariffUsers);
}
