import React, {useState} from "react";
import {Button} from "@root/Components/Controls";
import {CloseOutlined} from "@ant-design/icons";
import UnSubscribeModal from "./Components/UnSubscribeModal/UnSubscribeModal";

const UnSubscribe = () => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Button 
                type="stroke" 
                icon={<CloseOutlined />}
                onClick={() => setOpenModal(true)}>
                Отменить план
            </Button>
            <UnSubscribeModal 
                onCancel={() => setOpenModal(false)} 
                open={openModal} />
        </>
    );
};

export default UnSubscribe;
