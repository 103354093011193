const getLegalDocumentRoutes = (prefix:string = "") => {
    const lprefix = `${prefix}/documents`;
    return {
        personal_data_policy: `${lprefix}/personal-data-policy`,
        cookie_politics: `${lprefix}/cookie-politics`,
        privacy_policy: `${lprefix}/privacy-policy`,
    };
};

export default getLegalDocumentRoutes;
