import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {iActions} from "@root/Redux/Tariff/types";
import {PayloadAction} from "@reduxjs/toolkit";
import {delay} from "redux-saga/effects";
import useNotification from "@root/Hooks/useNotification/useNotification";

const DeleteUser = function* (action: PayloadAction<iActions.deleteUser>) {
    const notification = useNotification();
    const {onError, onSuccess, email} = action.payload;
    try {
        yield delay(1000); // УДАЛИТЬ 
        // yield* call(Api.Tariff.deleteUser, {email});
        onSuccess && onSuccess();
        notification({
            type: "info",
            message: "Приглашение отозвано"
        });
        yield* put(Actions.Tariff._deleteUserSuccess({
            email
        }));
    } catch (error) {
        notification({
            type: "error",
            message: "Произошла ошибка при отмене приглашения"
        });
        onError && onError();
        yield* put(Actions.Tariff._deleteUserError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Tariff.deleteUser, DeleteUser);
}
