import React from "react";
import styles from "./Logo.module.less";
import LogoText from "./Icons/TextLogo";
import IconLogo from "./Icons/IconLogo";
import {Link} from "react-router-dom";

const Logo = () => {
    return (
        <Link to={"/"} className={styles.link}>
            <div className={styles.logo}>
                <IconLogo />
                <LogoText />
            </div>
        </Link>
    );
};

export default Logo;
