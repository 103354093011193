import getLkRoutes from "./lk/lk";
import getAuthRoutes from "./auth/auth";
import getLegalDocumentRoutes from "./ld/ld";

export const routes = {
    lk: getLkRoutes(),
    auth: getAuthRoutes(),
    ld: getLegalDocumentRoutes()
};

export default routes;
