import {RuleObject} from "antd/lib/form";

export default [
    () => ({
        validator(_rule: RuleObject, value: any) {
            if (!value) {
                return Promise.reject(new Error("Это поле не может быть пустым."));
            }
            if (value.length > 100) {
                return Promise.reject("E-mail должен содержать не более 100 символов");
            }
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!emailRegex.test(value)) {
                return Promise.reject("E-mail не подходит");
            }
            return Promise.resolve();
        },
    })];
