import styles from "./Empty.module.less";
import React from "react";
import Icon from "./Icons/Icon";

const Empty = () => {
    return (
        <div className={styles.wrapper}>
            <Icon />
            <div>
                Вы пока не пригласили ни одного участника
            </div>
        </div>
    );
};

export default Empty;
