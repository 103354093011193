import React from "react";
import Loading from "../Loading/Loading";
import Empty from "../Empty/Empty";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Actions} from "@root/Redux/Tariff/TariffRedux";
import styles from "./Content.module.less";
import {Button} from "@root/Components/Controls";

const Content = () => {
    const myInvitesFetching = useAppSelector((state) => state.Tariff.myInvites.fetching);
    const cancelInviteFetching = useAppSelector((state) => state.Tariff.cancelInvite.fetching);
    const myInvites = useAppSelector((state) => state.Tariff.myInvites.data) || [];
    const dispatch = useAppDispatch();

    const onCancelInvite = (email: string) => {
        dispatch(Actions.cancelInvite({
            email
        }));
    };

    if (myInvitesFetching) return <Loading />;
    if (myInvites.length === 0) return <Empty />;
    return (
        <>
            {
                myInvites.map((invite) => (
                    <div key={invite} className={styles.invite}>
                        <div className={styles.email}>
                            {invite}
                        </div>
                        <Button 
                            loading={cancelInviteFetching}
                            type="stroke" 
                            className={styles.cancel}
                            onClick={() => onCancelInvite(invite)}>
                            Отозвать приглашение
                        </Button>
                    </div>
                ))
            }
        </>
    );
};

export default Content;
