import React, {useState} from "react";
import {Button} from "@root/Components/Controls";
import InviteUserModal from "./Components/InviteUserModal/InviteUserModal";

const InviteUser = () => {
    const [openModal, setOpenModal] = useState(false);
    
    return (
        <>
            <Button 
                type="primary" 
                onClick={() => setOpenModal(true)}>
                Добавить участников
            </Button>
            <InviteUserModal 
                onCancel={() => setOpenModal(false)} 
                open={openModal} />
        </>
    );
};

export default InviteUser;
