import {RuleObject} from "antd/lib/form";
import {NamePath} from "antd/lib/form/interface";

type getFieldValueFunction = (name: NamePath) => any

export default {
    newPassword: [
        ({getFieldValue}: {getFieldValue: getFieldValueFunction}) => ({
            validator(_rule: RuleObject, value: any) {
                if (!value) {
                    return Promise.reject(new Error("Это поле не может быть пустым."));
                }
                if (value.length > 100) {
                    return Promise.reject("Пароль должен содержать не более 100 символов");
                }
                if (getFieldValue("old_password") === value) {
                    return Promise.reject(new Error("Текущий и новый пароли совпадают"));
                }
                return Promise.resolve();
            },
        }),
    ],
    confirmPassword: [
        ({getFieldValue}: {getFieldValue: getFieldValueFunction}) => ({
            validator(_rule: RuleObject, value: any) {
                if (!value) {
                    return Promise.reject(new Error("Это поле не может быть пустым."));
                }
                if (value.length > 100) {
                    return Promise.reject("Пароль должен содержать не более 100 символов");
                }
                if (getFieldValue("new_password") === value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error("Пароли не совпадают"));
            },
        }),
    ]
};

