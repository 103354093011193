import {RuleObject} from "antd/lib/form";

export default [
    () => ({
        validator(_rule: RuleObject, value: any) {
            const regex = /^\s*$/;
            if (!value) {
                return Promise.reject(new Error("Это поле не может быть пустым."));
            }
            if (value.length > 25) {
                return Promise.reject("Поле должно содержать не более 25 символов");
            }
            if (regex.test(value)) {
                return Promise.reject("Поле не должно состоять только из пробелов");
            }
            return Promise.resolve();
        },
    })
];
