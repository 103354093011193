import React from "react";
import styles from "./ControlBlock.module.less";
import {TeamOutlined} from "@ant-design/icons";
import InviteUser from "./Modules/InviteUser/InviteUser";
import InvitedUsers from "./Modules/InvitedUsers/InvitedUsers";

const ControlBlock = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <div className={styles.title}>
                    Участники
                </div>
                <div className={styles.usersCount}>
                    {1} из 10 пользователей
                </div>
            </div>
            <div className={styles.control}>
                <InvitedUsers />
                <InviteUser />
            </div>
        </div>
    );
};

export default ControlBlock;
