import React, {useState} from "react";
import {Button} from "@root/Components/Controls";
import {FileAddOutlined} from "@ant-design/icons";
import AddPagesModal from "./Components/AddPagesModal/AddPagesModal";

const AddPages = () => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Button 
                type="stroke" 
                icon={<FileAddOutlined />}
                onClick={() => setOpenModal(true)}>
                Добавить страницы
            </Button>
            <AddPagesModal 
                onCancel={() => setOpenModal(false)} 
                open={openModal} />
        </>
    );
};

export default AddPages;
