import React from "react";
import TariffContent from "./Content/Tariff";
import useTitle from "@root/Hooks/useTitle/useTitle";

const Tariff = () => {
    useTitle("DeepDocs - Подписка");
    
    return <TariffContent />;
};

export default Tariff;
