import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {iActions} from "@root/Redux/Tariff/types";
import {PayloadAction} from "@reduxjs/toolkit";
import {delay} from "redux-saga/effects";

const mockData = [
    "user1@example.com",
    "user2@example.com",
    "user3@example.com",
    "user4@examuse.com",
    "user5@example.com",
    "user6@example.com",
    "user7@example.com",
    "user8@example.com",
    "user9@example.com",
    "user10@example.com"
];

const MyInvites = function* (action: PayloadAction<iActions.myTariff>) {
    const {onError, onSuccess} = action.payload;
    try {
        yield delay(1000); // УДАЛИТЬ 
        // const {data} = yield* call(Api.Tariff.myInvites);
        onSuccess && onSuccess();
        // yield* put(Actions.Tariff._myInvitesSuccess(data));
        yield* put(Actions.Tariff._myInvitesSuccess(mockData));
    } catch (error) {
        onError && onError();
        yield* put(Actions.Tariff._myInvitesError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Tariff.myInvites, MyInvites);
}
