import {RuleObject} from "antd/lib/form";
import {NamePath} from "antd/lib/form/interface";

type getFieldValueFunction = (name: NamePath) => any

export default [
    ({getFieldValue}: {getFieldValue: getFieldValueFunction}) => ({
        validator(_rule: RuleObject, value: any) {
            if (!value) {
                return Promise.reject(new Error("Это поле не может быть пустым."));
            }
            if (value.length > 100) {
                return Promise.reject("Пароль должен содержать не более 100 символов");
            }
            if (getFieldValue("password") === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error("Пароли не совпадают"));
        },
    })];
