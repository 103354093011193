import styles from "./Loading.module.less";
import React from "react";
import LoadingComponent from "@root/Components/Loading/Loading";

const Loading = () => {
    return (
        <div className={styles.wrapper}>
            <LoadingComponent>
                Загрузка пользователей
            </LoadingComponent>
        </div>
    );
};

export default Loading;
