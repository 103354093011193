import React from "react";
import {call, put, delay, take, fork, cancel} from "typed-redux-saga";
import Actions from "@actions";
import Api from "../../Api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Document/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

type getAnonymizedDocumentsAction = ReturnType<typeof Actions.Document.getAnonymizedDocuments>

const GetAnonymizedDocuments = function* (action: PayloadAction<iActions.getAnonymizedDocuments>) {
    const {payload} = action;
    const notification = useNotification();
    let queryCount: number = 0;
    while (queryCount < 5) {
        try {
            while (true) {
                const {data} = yield* call(Api.Document.getAnonymizedDocuments, payload);
                yield* put(Actions.Document._getAnonymizedDocumentsSuccess(data));
                payload.onSuccess && payload.onSuccess();
                yield* delay(5000);
            }
        } catch (error: any) {
            yield* delay(1000);
            queryCount += 1;
            if (queryCount === 4) {
                yield* put(Actions.Document._getAnonymizedDocumentsError());
                payload.onError && payload.onError();
                notification({
                    type: "error",
                    message: error.response.data.error 
                        ? error.response.data.error 
                        : "При получении списка анонимизированных документов произошла ошибка"
                });
            }
        }
    }
};

export default function* ():any {
    while (true) {
        const action: getAnonymizedDocumentsAction = yield* take(Actions.Document.getAnonymizedDocuments);
        const bgLoopTask = yield* fork(GetAnonymizedDocuments, action);
        yield* take(Actions.Document.stopGetAnonymizedDocuments);
        yield* cancel(bgLoopTask);
    }
}
