import React from "react";
import styles from "./Info.module.less";
import Badge from "./Components/Badge/Badge";
import {useAppSelector} from "@root/Hooks";

const Info = () => {
    const data = useAppSelector((state) => state.Tariff.myTariff.data);

    if (!data) return null;
    
    return (
        <div className={styles.wrapper}>
            <article>
                <p>
                    Ваш тарифный план
                </p>
                <span>
                    {data.plan.title} <Badge active={data.plan.active} />
                </span>
            </article>
            <article>
                <p>
                    Спишется {data.next_pay.date} 
                </p>
                <span>
                    {data.next_pay.price}₽
                </span>
            </article>
            <article>
                <p>
                    Количество страниц
                </p>                
                <span>
                    {data.pages.used} из  {data.pages.overall}
                </span>
            </article>
            <article>
                <p>
                    Количество участников
                </p>
                <span>
                    {data.users.active} из {data.users.overall}
                </span>
            </article>
        </div>
    );
};

export default Info;
