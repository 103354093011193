export default {
    Tariff: {
        my: "/v1/my_tariff",
        unSubscribe: "/v1/un_subscribe",
        addUsers: "/v1/add_users",
        addPages: "/v1/add_pages",
        inviteUser: "/v1/invite_user",
        myInvites: "/v1/my_invites",
        cancelInvite: "/v1/cancel_invite",
        myTariffUsers: "/v1/my_tariff_users",
        deleteUser: "/v1/delete_user"
    },
};
