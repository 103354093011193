import React from "react";
import {Button, Modal} from "@root/Components/Controls";
import styles from "./UnSubscribeModal.module.less";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Actions} from "@root/Redux/Tariff/TariffRedux";

type UnSubscribeModalProps = {
    open: boolean,
    onCancel: () => void
}

const UnSubscribeModal = (props: UnSubscribeModalProps) => {
    const dispatch = useAppDispatch();
    const {fetching} = useAppSelector((state) => state.Tariff.unSubscribe);

    const onUnSubscribe = () => {
        dispatch(Actions.unSubscribe({
            onSuccess: () => props.onCancel()
        }));
    };

    const onCancel = () => {
        if (!fetching) props.onCancel();
    };

    return (
        <Modal antProps={{
            open: props.open,
            onCancel,
            footer: null,
            title: "Хотите отменить подписку?",
            width: 440,
            className: styles.modal
        }}>
            <p>
                Если вы продолжите, ваш план будет отменен по окончанию текущего периода подписки.
            </p>
            <Button 
                type="primary"
                onClick={onUnSubscribe}
                loading={fetching}>
                Подтвердить отмену
            </Button>
        </Modal>
    );
};

export default UnSubscribeModal;
