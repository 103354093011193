import React from "react";
import CustomLoading from "@root/Components/Loading/Loading";

const Loading = () => {
    return (
        <CustomLoading>
            Загрузка информации о подписке
        </CustomLoading>
    );
};

export default Loading;
