import React, {useState} from "react";
import {Button} from "@root/Components/Controls";
import {UserAddOutlined} from "@ant-design/icons";
import AddUsersModal from "./Components/AddUsersModal/AddUsersModal";

const AddUsers = () => {
    const [openModal, setOpenModal] = useState(false);
    
    return (
        <>
            <Button 
                type="stroke" 
                icon={<UserAddOutlined />}
                onClick={() => setOpenModal(true)}>
                Добавить рабочие места
            </Button>
            <AddUsersModal 
                onCancel={() => setOpenModal(false)} 
                open={openModal} />
        </>
    );
};

export default AddUsers;
