import React from "react";
import styles from "./Tariffs.module.less";
import TariffCard from "../Components/TariffCard/TariffCard";
import {useAppSelector} from "@root/Hooks";
import {TariffsViewVariants} from "../../Types/Types";
import Loading from "../../Components/Loading/Loading";

type TariffsProps = {
    tariffsViewVariant: TariffsViewVariants
}

const Tariffs = (props: TariffsProps) => {
    const {tariffsViewVariant} = props;
    const {data: tariffs, error, fetching} = useAppSelector((state) => state.Common.tariffs);

    if (fetching) return <Loading />;

    if (error || !tariffs) return null;

    return (
        <div className={styles.wrapper}>
            {tariffsViewVariant === TariffsViewVariants.corp && (
                <div className={styles.corpTariffs}>
                    {tariffs.corporate.map((item) => (
                        <TariffCard key={item.title} {...item}/>
                    ))}
                </div>
            )}
            {tariffsViewVariant === TariffsViewVariants.pers && (
                <div className={styles.persTariffs}>
                    {tariffs.personal.map((item) => (
                        <TariffCard key={item.title} {...item}/>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Tariffs;
