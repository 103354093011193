import React from "react";
import GridContainer from "@components/GridContainer/GridContainer";
import {Input, Button, FormItem} from "@components/Controls";
import {Form} from "antd";
import {useAppDispatch} from "@root/Hooks";
import Actions from "@actions";
import styles from "./ProjectsAddForm.module.less";
import {titleRules} from "@utils/Form/Rules";

const ProjectsAddForm = () => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    
    const onCreate = () => {
        const data = {
            title: form.getFieldValue("title"),
            description: form.getFieldValue("description"),
        };
        dispatch(Actions.Projects.createProject(data));
        form.resetFields();
    };

    return (
        <GridContainer className={styles.grid}>
            <>
                <div className={styles.title}>Укажите основную информацию о проекте</div>
                <Form
                    name="CreateProject"
                    layout="vertical"
                    form={form}
                    requiredMark={false}
                    onFinish={onCreate}>
                    <FormItem
                        name="title"
                        rules={titleRules}
                        label="Название проекта">
                        <Input
                            placeholder={"Введите название"}
                            allowClear
                        />
                    </FormItem>
                    <FormItem
                        name="description"
                        label="Описание проекта"
                        className={styles.inputProjectDescription}>
                        <Input.TextArea
                            showCount
                            maxLength={512}
                            autoSize={{
                                minRows: 3,
                                maxRows: 3,
                            }}
                            placeholder={"Введите описание проекта"}
                        />
                    </FormItem>
                    <FormItem className={styles.lastFormItem}>
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            className={styles.button}>
                            Создать
                        </Button>
                    </FormItem>
                </Form>
            </>
        </GridContainer>
    );
};

export default ProjectsAddForm;
