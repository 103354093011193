import rootSagaCreator from "../rootSagaCreator";
import AddPagesSaga from "./AddPagesSaga";
import AddUsersSaga from "./AddUsersSaga";
import CancelInvite from "./CancelInvite";
import DeleteUser from "./DeleteUser";
import InviteUser from "./InviteUser";
import MyInvites from "./MyInvites";
import MyTariffSaga from "./MyTariffSaga";
import MyTariffUsers from "./MyTariffUsers";
import unSubscribeSaga from "./unSubscribeSaga";

export default function* rootSaga() {
    yield rootSagaCreator([
        MyTariffSaga,
        unSubscribeSaga,
        AddUsersSaga,
        AddPagesSaga,
        InviteUser,
        MyInvites,
        CancelInvite,
        MyTariffUsers,
        DeleteUser
    ], "TARIFF");
}
