import React, {ChangeEvent, useState} from "react";
import {Button, Modal, StateInputNumber} from "@root/Components/Controls";
import styles from "./AddPagesModal.module.less";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Divider} from "antd";
import {Actions} from "@root/Redux/Tariff/TariffRedux";

type AddPagesModalProps = {
    open: boolean,
    onCancel: () => void
}

const AddPagesModal = (props: AddPagesModalProps) => {
    const [addPagesCount, setAddPageCount] = useState(1);
    const dispatch = useAppDispatch();
    const myTariff = useAppSelector((state) => state.Tariff.myTariff.data);
    const addPagesFetching = useAppSelector((state) => state.Tariff.addPages.fetching);
    const pricePerPage = 100; // В дальнейшем получать это из запроса тарифных планов

    if (!myTariff) return null;

    const onAddPages = () => {
        dispatch(Actions.addPages({
            addPagesCount,
            newPrice: (pricePerPage * addPagesCount) + myTariff.next_pay.price,
            onSuccess: () => props.onCancel()
        }));
    };

    const onCancel = () => {
        if (!addPagesFetching) props.onCancel();
    };

    const onChangePages = (event: ChangeEvent<HTMLInputElement>) => {
        const numberRegex = /^\d+$/; 
        const value = event.target.value;
        if (!value) setAddPageCount(0);
        if (!numberRegex.test(value)) return;
        if (Number(value) < 1) setAddPageCount(0);
        if (Number(value) > 99) setAddPageCount(99);
        if (Number(value) > 0 && Number(value) < 100) setAddPageCount(Number(value));
    };

    return (
        <Modal antProps={{
            open: props.open,
            onCancel,
            footer: null,
            title: "Добавить страницы",
            width: 440,
            className: styles.modal
        }}>
            <p>
                Выберите количество дополнительных страниц для обезличивания. По окончании платежного периода ваш тарифный план будет автоматически продлен и обновлен.
            </p>
            <StateInputNumber 
                className={styles.inputNumber}
                minusButtonProps={{
                    onClick: () => setAddPageCount(addPagesCount - 1),
                    disabled: addPagesCount === 0,
                    loading: addPagesFetching
                }}
                inputProps={{
                    min: 0,
                    max: 99,
                    value: addPagesCount,
                    onChange: onChangePages
                }}
                plusButtonProps={{
                    onClick: () => setAddPageCount(addPagesCount + 1),
                    disabled: addPagesCount === 99,
                    loading: addPagesFetching
                }} />
            <Divider type="horizontal" className={styles.divider}/>
            <div className={styles.info}>
                <span>Выш тариф {myTariff.plan.title}</span>
                <span>{myTariff.next_pay.price} ₽ / мес</span>
            </div>
            <div className={styles.subInfo}>
                <span>Следующее списание {myTariff.next_pay.date}</span>
                <span>{Number(myTariff.next_pay.price) + Number(pricePerPage * addPagesCount)} ₽</span>
            </div>
            <Button 
                type="primary"
                loading={addPagesFetching}
                onClick={onAddPages} 
                disabled={addPagesCount === 0}>
                Подтвердить изменение
            </Button>
        </Modal>
    );
};

export default AddPagesModal;
