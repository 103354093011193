import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Document/types";
import {RequestFullState} from "@root/Utils/Redux/types";
import {
    getFullState,
    requestStart, 
    requestError,
    requestSuccess,
} from "@root/Utils/Redux/getRequestState";
import {Documents} from "@types";

type iState = {
    pdfDocument: RequestFullState<Blob>,
    anonymizedDocuments: RequestFullState<Documents.AnonymizedDocument[]>,
}

const initialState:iState = {
    pdfDocument: getFullState(),
    anonymizedDocuments: getFullState(),
};

const Slice = createSlice({
    initialState,
    name: "Document",
    reducers: {
        //PdfDocument
        getPdfDocument: (state, action: PayloadAction<iActions.getPdfDocument>) => {
            requestStart(state.pdfDocument);
        },
        _getPdfDocumentSuccess: (state, action: PayloadAction<iActions._getPdfDocumentSuccess>) => {
            requestSuccess(state.pdfDocument, action.payload);
        },
        _getPdfDocumentError: (state) => {
            requestError(state.pdfDocument);
        },
        erasePdfDocument: (state) => {
            state.pdfDocument = initialState.pdfDocument;
        },
        downloadOriginalDocument: (state, action: PayloadAction<iActions.downloadOriginalDocument>) => {
            return state;
        },
        getAnonymizedDocuments: (state, action: PayloadAction<iActions.getAnonymizedDocuments>) => {
            requestStart(state.anonymizedDocuments);
        },
        _getAnonymizedDocumentsSuccess: (state, action: PayloadAction<iActions._getAnonymizedDocumentsSuccess>) => {
            requestSuccess(state.anonymizedDocuments, action.payload);
        },
        _getAnonymizedDocumentsError: (state) => {
            requestError(state.anonymizedDocuments);
        },
        stopGetAnonymizedDocuments: (state) => {
            return state;
        },
        eraseAnonymizedDocuments: (state) => {
            state.anonymizedDocuments = initialState.anonymizedDocuments;
        },
        deleteAnonymizedDocuments: (state, action: PayloadAction<iActions.deleteAnonymizedDocuments>) => {
            return state;
        },
        _deleteAnonymizedDocumentsSuccess: (state, action: PayloadAction<iActions.deleteAnonymizedDocuments>) => {
            action.payload.anonymization_request_ids.forEach((id) => {
                if (state.anonymizedDocuments.data) {
                    state.anonymizedDocuments.data = state.anonymizedDocuments.data.filter((item) => item.id !== id);
                }
            });
            
        }
    }
});

export const Actions = Slice.actions;
export default Slice.reducer;
